import React                                    from 'react';
import { Navigate }                             from 'react-router';
import Split                                    from 'react-split';
import { Link }                                 from 'react-router-dom';

import * as DVIRecordType                       from 'shared/types/DVIRecord';
import * as OrderType                           from 'shared/types/Order';
import * as VehicleType                         from 'shared/types/Vehicle';
import * as FixedRouteType                      from 'shared/types/FixedRoute';
import * as WSPayloadType                       from 'shared/types/WSPayload';
import * as LatLngType                          from 'shared/types/LatLng';
import * as LegType                             from 'shared/types/Leg';
import * as UserType                            from 'shared/types/User';
import * as AgencyType                          from 'shared/types/Agency';
import * as DurationType                        from 'shared/types/Duration';
import jsonParse                                from 'shared/utils/jsonParse';
import dayjs                                    from 'shared/utils/day-timezone';

import Alert                                    from 'utils/Alert';
import tectransit                               from 'utils/TecTransit';
import MapVehicle                               from 'utils/MapVehicle';
import MapOptions                               from 'utils/MapOptions';
import getApiPromise                            from 'utils/getApiPromise';
import * as ApcType                             from 'utils/APC';
import * as TecTransITPlugin                    from 'plugins/TecTransIT';
import * as images                              from 'images';

import * as MenuItem                            from 'components/MenuItem';
import RouteContainer                           from 'components/RouteContainer';
import * as googleMapsLinks                     from 'utils/googleMapsLinks';

import BadgeScanning                            from 'Driver/components/BadgeScanning';

interface DriverRouteChildProps {
    driverRoute : DriverRoute
}
class VehicleProps extends React.Component<DriverRouteChildProps,{showTechInfo?:boolean;}> {
    //
    public autoZoom         : boolean = true;
    constructor( props:DriverRouteChildProps ) {
        super(props);
        this.state = {
        }
    }
    private getFixedRoutePassengerCountersTable( serverVehicle:WSPayloadType.Vehicle.Hydrated ) : React.ReactElement {
        const tripCounts = this.props.driverRoute.getTripCounts();
        const maxSeatTypeCounts = {
            seats       : (serverVehicle.seats||Infinity),
            wheelchairs : (serverVehicle.wheelchairs||Infinity)
        };
        const setTripCounts = ( delta:Record<string,number> ) => {
            const newPassengers     = {...tripCounts.passengers};
            const newBoardings      = {...tripCounts.boardings};
            Object.entries(delta).forEach( ([key,value]) => {
                newPassengers[key] = Math.max(0,(newPassengers[key]||0)+value);
                if( value>0 )
                    newBoardings[key] = (newBoardings[key]||0)+value;
            });
            const newSeatTypeCounts = VehicleType.getSeatTypeCounts(newPassengers);
            // @ts-expect-error
            const invalidNewSeatCountEntry  = ([seatType_,count_]) => {
                const seatType = seatType_  as ('seats'|'wheelchairs');
                const count    = count_     as number;
                if( count>maxSeatTypeCounts[seatType] ) {
                    this.props.driverRoute.alert.set(`The number of ${seatType} passengers cannot exceed ${maxSeatTypeCounts[seatType]}`,3000);
                    return true;
                }
                if( count<0 ) {
                    this.props.driverRoute.alert.set(`The number of ${seatType} passengers cannot be negative`,3000);
                    return true;
                }
                return false;      // The seatType value exceeds maximum and was increased
            };
            if( Object.entries(newSeatTypeCounts).some(invalidNewSeatCountEntry) )
                return false;
            tripCounts.passengers = newPassengers;
            tripCounts.boardings  = newBoardings;
            tripCounts.seconds    = Math.round(Date.now()/1000);
            this.props.driverRoute.sendToServer({
                type         : 'tripCounts',
                ...tripCounts
            });
            return true;
        };
        if( !tectransit.agency.issue426_mode ) {
            const updatePassengersCountElement = ( fareName:string ) => {
                const elementId              = `${fareName}PassengersCount`;
                const passengersCountElement = document.getElementById(elementId) as HTMLSpanElement;
                if( passengersCountElement ) {
                    passengersCountElement.innerText = (tripCounts.passengers[fareName]||0).toString();
                }
                else {
                    console.error(`Cannot find element with id '${elementId}'`);
                }
            }
            const getFRFareCategoryCounterRow = ( fareEntry:[string,number] ) : React.ReactElement => {
                const [fareName,fareDollars]    = fareEntry;
                const farePassengers            = tripCounts.passengers[fareName] || 0;
                const fareBoardings             = tripCounts.boardings[fareName] || 0;
                return this.props.driverRoute.getRow(
                    <>
                        {fareName} (${(fareBoardings*fareDollars).toFixed(2)})
                        &nbsp;
                        <span id={`${fareName}PassengersCount`}>{farePassengers}</span>
                    </>,
                    <>
                        <images.PlusSign  width={36} height={36} onClick={() => {
                            if( setTripCounts({[fareName]:+1}) )
                                updatePassengersCountElement(fareName);
                        }}/>
                        <images.MinusSign width={36} height={36} onClick={() => {
                            if( setTripCounts({[fareName]:-1}) )
                                updatePassengersCountElement(fareName);
                        }}/>
                    </>,
                    {verticalAlign:'center'}
                );
            }
            return (
                <table>
                    <tbody>
                    {Object.entries(tectransit.agency.fixed_route_fares||{}).map(getFRFareCategoryCounterRow)}
                    </tbody>
                </table>
            );
        }
        else {
            const faresBySeatType = Object.entries(tectransit.agency.fixed_route_fares||{}).reduce( (acc,[fareName,fareDollars]) => {
                (acc[VehicleType.getSeatTypeByFareName(fareName)])[fareName] = fareDollars;
                return acc;
            },{
                seats       : {} as Record<string,number>,
                wheelchairs : {} as Record<string,number>
            });
            const updatePassengersCountElement = ( seatType:('seats'|'wheelchairs') ) => {
                const elementId              = `${seatType}PassengersCount`;
                const passengersCountElement = document.getElementById(elementId) as HTMLTableCellElement;
                if( passengersCountElement ) {
                    passengersCountElement.innerText = Object.keys(faresBySeatType[seatType]).reduce( (acc,fareName) => {
                        return acc+(tripCounts.passengers[fareName]||0);
                    },0).toString();
                }
                else {
                    console.error(`Cannot find element with id '${elementId}'`);
                }
            }
            return (
                <table>
                    <tbody>
                    {Object.entries(faresBySeatType).map( ([seatType_,seatTypeFares],seatTypeNdx) => {
                        const seatType = seatType_ as ('seats'|'wheelchairs');
                        return Object.entries(seatTypeFares).map( ([fareName,fareDollars],fareNameNdx,fareEntries) => {
                            const fareBoardings     = tripCounts.boardings[fareName] || 0;
                            return (
                                <tr key={Date.now()+Math.random()}>
                                    <td>{fareName} (${(fareBoardings*fareDollars).toFixed(2)})</td>
                                    <td><images.PlusSign width={36} height={36} onClick={()=> {
                                            if( setTripCounts({[fareName]:+1}) )
                                                updatePassengersCountElement(seatType)
                                        }}/>
                                    </td>
                                    {(fareNameNdx===0) && (<>
                                        <td id={`${seatType}PassengersCount`} rowSpan={fareEntries.length}>
                                            {Object.entries(seatTypeFares).reduce((acc,[fareName,fareDollars])=>{
                                                return acc+(tripCounts.passengers[fareName]||0);
                                            },0)}
                                        </td>
                                        <td rowSpan={fareEntries.length}>
                                            <images.MinusSign width={36} height={36} onClick={() => {
                                                // Per issue https://github.com/TecTransit/TecTransit/issues/426
                                                // we get to choose the fare category we decrease the number of 
                                                // passengers in. Let's choose the largest one
                                                const largestFareName = [...Object.entries(tripCounts.passengers)].sort((e1,e2) => {
                                                    return e2[1]-e1[1];
                                                })[0][0];
                                                if( setTripCounts({[largestFareName]:-1}) )
                                                    updatePassengersCountElement(seatType);
                                            }}/>  
                                        </td>
                                    </>)}
                                </tr>
                            );
                        });
                    })}
                    </tbody>
                </table>
            );
        }
    }
    // 
    render() {
        const driverRoute   = this.props.driverRoute;
        const wsPayload     = driverRoute.wsPayload;
        const serverVehicle = wsPayload?.vehiclesById[this.props.driverRoute.state.vehicle?._id??-1];
        if( !serverVehicle )
            return false;
        const rows = [
            driverRoute.getRow(
                "Vehicle #",
                serverVehicle._id
            ),
            driverRoute.getRow(
                "Time",
                dayjs(wsPayload.seconds*1000).tz(tectransit.agency.time_zone).format('MM/DD/YYYY HH:mm:ss')
            )
        ];
        if( serverVehicle.unavailability_reason )
            rows.push(driverRoute.getRow(
                "Availability",
                <span style={{color:'red'}}>{serverVehicle.unavailability_reason}</span>
            ));
        rows.push(
            driverRoute.getRow(
                'Current Location',
                googleMapsLinks.getPlace(
                    driverRoute.addressLatLng,
                    googleMapsLinks.getAddress(driverRoute.latLngAddress,(line1,line2)=>{
                        const result = <>{line1}</> as React.ReactElement;
                        const routeLatLng = (serverVehicle.route.legs||[]).map(l=>l.steps).flat().map(s=>s.start_location).find(ll=>!!ll);
                        return (driverRoute.isCloseTo(routeLatLng) || tectransit.agency.simulate_reported_location) ? result : <span style={{color:'#ff0000'}}>{result}</span>;
                    })
                )
            ),
            driverRoute.getRow(
                <span onDoubleClick={e=>{this.setState({showTechInfo:!this.state.showTechInfo})}}>Auto Zoom</span>,
                <input type="checkbox" id="autoZoom" defaultChecked={this.autoZoom} onChange={(e)=>{
                    this.autoZoom = e.target.checked;
                }}/>
            )
        );
        if( this.state.showTechInfo ) {
            rows.push(
                driverRoute.getRow('Apc Message',driverRoute.apcMessage),
                driverRoute.getRow('Plugin Config',JSON.stringify(driverRoute.pluginBuildConfig)),
                driverRoute.getRow('Tcp Port Result',JSON.stringify(driverRoute.openTcpPortResult)),
                driverRoute.getRow('Apc Data Packets',driverRoute.apcState.dataPackets),
                driverRoute.getRow('Apc Gps Packets',driverRoute.apcState.gpsPackets),
                driverRoute.getRow('Apc Heart Packets',driverRoute.apcState.heartbeatPackets),
                driverRoute.getRow('Apc Unknown Packets',driverRoute.apcState.unknownPackets),
                driverRoute.getRow('Apc Total Ins',driverRoute.apcState.totalIns),
                driverRoute.getRow('Apc Total Outs',driverRoute.apcState.totalOuts),
            );
        }
        if( serverVehicle.fixed_route_trip ) {
            const mapVehicle    = driverRoute.mapVehicle;
            if( mapVehicle ) {
                if( (mapVehicle.frStopArrivalLateSecs||0)>30 )
                    rows.push(driverRoute.getRow(
                        `Arrival Late to current stop`,
                        <span style={{color:'red'}}><strong>{DurationType.secondsToHMS(mapVehicle.frStopArrivalLateSecs)}</strong></span>
                    ));
                if( (mapVehicle.frStopDepartureEarlySecs||0)>30 )
                    rows.push(driverRoute.getRow(
                        `Depart Early from last stop`,
                        <span style={{color:'red'}}><strong>{DurationType.secondsToHMS(mapVehicle.frStopDepartureEarlySecs)}</strong></span>
                    ));
            }
            rows.push(
                driverRoute.getRow(
                    'Trip',
                    `${serverVehicle.fixed_route_trip.name} (${serverVehicle.fixed_route_trip.service_id})`
                )
            )
            if( !tectransit.agency.issue422_public_key )
                rows.push(
                    driverRoute.getRow(
                        'Passengers',
                        this.getFixedRoutePassengerCountersTable(serverVehicle),
                        {verticalAlign:'top'}
                    )
                );
        }
        return rows.map((row,ndx)=>(<React.Fragment key={ndx}>{row}</React.Fragment>));
    }
}
class BadgeScanner extends React.Component<DriverRouteChildProps,{}> {
    needsToRender() : boolean {
        if( !tectransit.agency.issue422_public_key )
            return false;   // passengers are not authenticated
        const driverRoute   = this.props.driverRoute;
        if( !driverRoute.mapVehicle?.serverVehicle?.fixed_route_trip )
            return false;   // only fixed route vehicles have boarding
        const firstFrStep = (driverRoute.mapVehicle.serverVehicle.route.legs||[]).flatMap(l=>l.steps).find(s=>s.maneuver==='fixed_route_stop');
        if( !firstFrStep?.stop )
            return false;
        return true;
        //return driverRoute.isCloseTo(firstFrStep.stop);
    }
    render() {
        if( !this.needsToRender() )
            return false;
        return this.props.driverRoute.getRow(
            'Boarding',
            <BadgeScanning onBoarding={(userId:number) => {
                const serverVehicle = this.props.driverRoute.mapVehicle?.serverVehicle;
                if( !serverVehicle )
                    throw Error(`No server vehicle`);
                const frTrip      = serverVehicle.fixed_route_trip;
                if( !frTrip )
                    throw Error(`No fixed route trip`);
                const firstFrStep = (serverVehicle.route.legs||[]).flatMap(l=>l.steps).find(s=>s.maneuver==='fixed_route_stop');
                const body = {
                    user_id     : userId,
                    vehicle_id  : serverVehicle._id,
                    trip_id     : frTrip._id,
                    stop_id     : firstFrStep?.stop?._id||-1
                };
                return getApiPromise<UserType.Dehydrated>("/api/driver/user/boarding","POST",body).then( res => {
                    if( res.err )
                        throw Error(res.err);
                    return UserType.hydrate(res);
                });
            }}/>,
            {verticalAlign:'top'}
        );
     }
}
class DrivingInstructions extends React.Component<DriverRouteChildProps,{}> {
    private tableWidth  = "100%";
    private tableStyle  = {
        borderSpacing   : 0,
        marginLeft      : 'auto',
        marginRight     : 'auto'
    };
    render() {
        const driverRoute   = this.props.driverRoute;
        const wsPayload     = driverRoute.wsPayload;
        const serverVehicle = wsPayload?.vehiclesById[this.props.driverRoute.state.vehicle?._id??-1];
        if( !serverVehicle )
            return false;
        const getLegRows = ( seconds:number, leg:LegType.Leg, lndx:number ) => {
            const rows = [] as JSX.Element[];
            if( serverVehicle.fixed_route_trip ) {
                // This is a fixed route vehicle
                const step = leg.steps[0];
                if( step?.maneuver==='fixed_route_stop' ) {
                    const stop = step.stop as FixedRouteType.Stop;
                    rows.push(
                        driverRoute.getRow(
                            '',
                            <>Stop <strong>{stop.name||'??'}</strong></>
                        )
                    );
                }
                if( leg.distance?.value || leg.duration?.value ) {
                    rows.push(
                        driverRoute.getRow(
                            dayjs(seconds*1000).tz(tectransit.agency.time_zone).format("HH:mm"),
                            `Drive ${leg.distance?.text||'0m'} for ${leg.duration?.text||'0s'}`
                        )
                    );
                }
            }
            else {
                const pickupOrders    = (leg.pickup.orders||[]).filter(driverRoute.isOrderPickupable);
                const dropoffOrders   = (leg.dropoff.orders||[]).filter(driverRoute.isOrderDropoffable);
                const pickupDuration  = pickupOrders.reduce((acc,order)=>(acc+AgencyType.getBoardingSeconds(tectransit.agency,order)),0);
                const dropoffDuration = pickupOrders.reduce((acc,order)=>(acc+AgencyType.getBoardingSeconds(tectransit.agency,order)),0);
                const pickupSeconds  = seconds;
                const driveSeconds   = pickupSeconds+pickupDuration;
                const dropoffSeconds = seconds+(leg.duration?.value||0)-dropoffDuration;
                // This is an on-demand vehicle
                const getOrder = (
                    style               :   Record<string,any>,
                    order               :   OrderType.Order,
                    eventName           :   ('pickup'|'dropoff'),
                    defaultEtaSeconds   :   number,
                    showButtons         :   boolean
                ) => {
                    const legEvent   = leg[eventName];
                    const orderEvent = order[eventName];
                    const getEta = () => {
                        // If this is a regular order, it has an ETA
                        const eta_ms = order._id ? orderEvent.eta : (legEvent.seconds||defaultEtaSeconds)*1000;
                        return dayjs(eta_ms).tz(tectransit.agency.time_zone).format("HH:mm:ss");
                    }
                    const getPickupButtons = ( order:OrderType.Order ) => {
                        return driverRoute.getRow(
                            <span>
                                <button className="btn-small" onClick={(e)=>driverRoute.onNoShow(e,order)}>no show</button>
                                <button className="btn-small" onClick={(e)=>driverRoute.onPickup(e,order)}>pickup</button>
                            </span>
                        );
                    }
                    const getDropoffButtons = ( order:OrderType.Order ) => {
                        return driverRoute.getRow(
                            <button className="btn-small" onClick={(e)=>driverRoute.onDropoff(e,order)}>dropoff</button>
                        )
                    }
                    const orderType = (order.type==='pickup_ordered_at') ? `pu @ ${dayjs(order.ordered_at).tz(tectransit.agency.time_zone).format("HH:mm")}` :
                                        (order.type==='dropoff_ordered_at')? `do @ ${dayjs(order.ordered_at).tz(tectransit.agency.time_zone).format("HH:mm")}` :
                                        (order.type||'');
                    return (
                        <table key={order._id||order.scheduler_id} style={{...style,borderRadius:'0.5rem',boxShadow:'0 4px 25px 0 rgb(0 0 0 / 10%)',padding:'0.5rem'}}>
                            <tbody>
                                <tr>
                                    <td>{eventName}</td>
                                    <td title={order.scheduler_id}>
                                        order #{order._id||'n/a'} of {driverRoute.getUserLink(order.user)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>{orderType}</td>
                                </tr>
                                <tr>
                                    <td>Eta</td>
                                    <td>{getEta()}</td>
                                </tr>
                                {(showButtons || tectransit.agency.always_show_stop_buttons) && (
                                    (eventName==='pickup') ? getPickupButtons(order) :
                                    (eventName==='dropoff') ? getDropoffButtons(order) :
                                    ''
                                )}
                            </tbody>
                        </table>
                    );
                }
                if( pickupOrders.length>0 ) {
                    rows.push(
                        driverRoute.getRow(
                            dayjs(pickupSeconds*1000).tz(tectransit.agency.time_zone).format("HH:mm"),
                            googleMapsLinks.getDirections(
                                leg.steps[0]?.start_location||leg.pickup.latlng,
                                pickupOrders.find(o=>o.pickup.address)?.pickup.address||leg.pickup.address,
                                googleMapsLinks.onlyFirstLineAddressFormatter
                            )
                        ),
                        driverRoute.getRow(
                            '',
                            pickupOrders.map( (order,ondx) => {
                                return getOrder(
                                    {background:'#dcdcdc',width:'100%'},
                                    order,
                                    'pickup',
                                    pickupSeconds,
                                    lndx===0
                                );
                            })
                        )
                    );
                }
                if( leg.distance?.value || leg.duration?.value ) {
                    rows.push(
                        driverRoute.getRow(
                            dayjs(driveSeconds*1000).tz(tectransit.agency.time_zone).format("HH:mm"),
                            `Drive ${leg.distance?.text} for ${leg.duration?.text}`
                        )
                    );
                }
                if( dropoffOrders.length>0 ) {
                    rows.push(
                        driverRoute.getRow(
                            dayjs(dropoffSeconds*1000).tz(tectransit.agency.time_zone).format("HH:mm"),
                            googleMapsLinks.getPlace(
                                leg.steps[leg.steps.length-1]?.end_location||leg.dropoff.latlng,
                                dropoffOrders.find(o=>o.dropoff.address)?.dropoff.address||leg.dropoff.address,
                                googleMapsLinks.onlyFirstLineAddressFormatter
                            )
                        ),
                        driverRoute.getRow(
                            '',
                            dropoffOrders.map((order,ondx)=>{
                                return getOrder(
                                    {background:'#dcdcdc',width:'100%'},
                                    order,
                                    'dropoff',
                                    dropoffSeconds,
                                    (lndx===0) && ((leg.distance?.value||0)<tectransit.agency.maneuver_announcement_meters)
                                );
                            })
                        )
                    );
                }
            }
            return rows;
        }
        const legRows  = serverVehicle.route.legs.reduce((acc,leg,lndx) => {
            acc.elements.push(...getLegRows(acc.seconds,leg,lndx));
            acc.seconds += leg.duration?.value||0;
            return acc;
        },{
            elements : [] as JSX.Element[],
            seconds  : wsPayload.seconds
        }).elements;
        const columns = ['ETA',''];
        return (legRows.length<1) ? false : driverRoute.getRow(
            <table width={this.tableWidth} style={this.tableStyle}>
                <thead>
                    <tr>
                        {columns.map((th,ndx)=>(<th key={ndx}>{th}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {legRows.map((row,ndx)=>(<React.Fragment key={ndx}>{row}</React.Fragment>))}
                </tbody>
            </table>
        );
    }
}

class DriverMapVehicle extends MapVehicle {
    private currentLatLngContainer : { currentLatLng?:VehicleType.ReportedLocation };
    constructor( googleMap:google.maps.Map, currentLatLngContainer: { currentLatLng:VehicleType.ReportedLocation } ) {
        super(googleMap);
        this.currentLatLngContainer = currentLatLngContainer;
    }
    protected getGpsLatLng() {
        return this.currentLatLngContainer.currentLatLng;
    }
    protected getLegs() {
        // For drivers show only the first 2 legs
        return (this.serverVehicle.route.legs||[]).slice(0,2);
    }
}

interface Props {
}
interface APCState {
    dataPackets      : number;
    gpsPackets       : number;
    heartbeatPackets : number;
    unknownPackets   : number;
    totalIns         : number;
    totalOuts        : number;
}
export default class DriverRoute extends RouteContainer {

    private static frCounts : FixedRouteType.TripCounts = {
        resetMs         : -1,
        tripId          : -1,
        seconds         : -1,
        passengers      : {},
        boardings       : {},
        apcPassengers   : 0,
        apcBoardings    : 0
    }
    private static frCountsVehicleId : number = -1;
    private static frCountsResetMs   : number = -1;

    private ws?                         : WebSocket;
    private currentLatLng?              : VehicleType.ReportedLocation;
    private lastLatLngLookupAt          : number  = 0;
    private logMessage?                 : string;
    private playedLegsCount             : number = 0;
    private pretendedStatusByOrderId    : Record<string,OrderType.Status> = {};
    private pickupStatuses              = { scheduled:1 };
    private dropoffStatuses             = { pickedup:1 };

    // Initialized in componentDidMount
    private mapBoundaries?              : google.maps.Polyline[];
    private windowSizeListener?         : any;
    private googleMapRenderings         : number = 0;
    private lastTimeGeoPositionSent     : number = 0;
    private offlineWSMessages           : WSPayloadType.WSMessage[] = [];
    private geoPositionWatcher?         : any;
    private badgeScannerRendered        : boolean = false;

    // Initialized at first render
    private vehicleProps?               : (VehicleProps|null);
    private badgeScanner?               : (BadgeScanner|null);
    private drivingInstructions?        : (DrivingInstructions|null);

    // Used by above components
    // @ts-expect-error
    public props                        : Props;
    public state                        : {
        splitterDirection    : ('horizontal'|'vertical');
        vehicle?             : VehicleType.Vehicle;
        dviRecord?           : DVIRecordType.DVIRecord;
        redirectToPti?       : boolean;
    } = {
        splitterDirection    : 'horizontal'
    }
    public alert                        : Alert = new Alert();
    public mapVehicle?                  : DriverMapVehicle;
    public googleMap?                   : google.maps.Map;
    public wsPayload?                   : WSPayloadType.WSPayload.Hydrated;
    public addressLatLng?               : LatLngType.LatLng;
    public latLngAddress                : string  = '...';
    public pluginBuildConfig            : (Record<string,any>|undefined);
    public openTcpPortResult            : (TecTransITPlugin.Result<TecTransITPlugin.OpenTcpPortArgs>|undefined);
    public apcMessage?                  : string;
    public apcState                     : APCState = {
        dataPackets      : 0,
        gpsPackets       : 0,
        heartbeatPackets : 0,
        unknownPackets   : 0,
        totalIns         : 0,
        totalOuts        : 0
    };
    public getRow( name:React.ReactNode, value?:React.ReactNode, style:React.CSSProperties={} ) : JSX.Element {
        if( value===undefined )
            return (
                <tr style={style} key={Date.now()+Math.random()}>
                    <td colSpan={2} valign="top">
                        {name}
                    </td>
                </tr>
            )
        return (
            <tr key={Date.now()+Math.random()}>
                <td style={style}>
                    {name}
                </td>
                <td style={style}>
                    {value}
                </td>
            </tr>
        );
    }
    public getUserLink( user?:Partial<UserType.User> ) {
        if( !user )
            return 'n/a';
        return <Link key={`user_${user?._id}`} to={`/Driver/Passenger/${user?._id}`}>{user?.name}</Link>;
    }
    public isCloseTo( ll?:LatLngType.LatLng ) : boolean {
        if( !this.mapVehicle )
            return false;
        const accuracyMeters = this.currentLatLng?.accuracy||5;
        const maxMetersAway  = tectransit.agency.maneuver_announcement_meters||5;
        return this.mapVehicle.isCloseTo(ll,Math.max(accuracyMeters*1.5,maxMetersAway));
    }
    public sendToServer( wsMessage:WSPayloadType.WSMessage ) {
        try {
            if( !this.ws )
                throw Error(`websocket is not open`);
            if( this.ws.readyState !== WebSocket.OPEN )
                throw Error(`websocket state is ${this.ws.readyState}`);
            return this.ws.send(JSON.stringify(wsMessage));
        }
        catch( err ) {
            console.warn(`Cannot send to web socket ${(err as Error).message}`,wsMessage);
            this.offlineWSMessages.push(wsMessage);
        }
    }
    public isOrderPickupable = ( order:OrderType.Order ) : boolean => {
        return (this.pretendedStatusByOrderId[order._id!]||order.status) in this.pickupStatuses;
    }
    public isOrderDropoffable = ( order:OrderType.Order ) : boolean => {
        return (this.pretendedStatusByOrderId[order._id!]||order.status) in this.dropoffStatuses;
    }
    public onNoShow = (e:React.MouseEvent<HTMLButtonElement>, order:OrderType.Order ) => {
        e.preventDefault();
        e.stopPropagation();
        if( !window.confirm(`Do you confirm that user '${order.user?.name}' did not show for pickup of order #${order._id}`) )
            return Promise.resolve(false);
        return this.getOrderApiPromise(0,order,{status:'noshow',ms:Date.now()});
    }
    public onPickup = ( e:React.MouseEvent<HTMLButtonElement>, order:OrderType.Order ) => {
        e.preventDefault();
        e.stopPropagation();
        return this.getOrderApiPromise(0,order,{status:'pickedup',ms:Date.now()});
    }
    public onDropoff = ( e:React.MouseEvent<HTMLButtonElement>, order:OrderType.Order ) => {
        e.preventDefault();
        e.stopPropagation();
        return this.getOrderApiPromise(0,order,{status:'finished',ms:Date.now()});
    }
    public getTripCounts() : FixedRouteType.TripCounts {
        // back to us and server is not the source of truth about trip counts, the client is. Therefore
        // the client keeps its own track of trip counts and re-sets them only when the vehicle changes
        // or when the day changes.
        const vehicleId             = this.state.vehicle?._id??-1;
        const serverVehicle         = this.mapVehicle?.serverVehicle || this.wsPayload?.vehiclesById[vehicleId];
        const fixedRouteTripCounts  = serverVehicle?.fixed_route_trip_counts || {} as FixedRouteType.TripCounts;
        const frCountsResetMs       = fixedRouteTripCounts.resetMs||-1;
        if( (DriverRoute.frCountsVehicleId!==vehicleId) || (DriverRoute.frCountsResetMs!==frCountsResetMs) ) {
            // reset counts
            DriverRoute.frCounts.passengers      = fixedRouteTripCounts.passengers || {};
            DriverRoute.frCounts.boardings       = fixedRouteTripCounts.boardings || {};
            DriverRoute.frCounts.apcPassengers   = fixedRouteTripCounts.apcPassengers || 0;
            DriverRoute.frCounts.apcBoardings    = fixedRouteTripCounts.apcBoardings || 0;
            DriverRoute.frCountsVehicleId        = vehicleId;
            DriverRoute.frCountsResetMs          = frCountsResetMs;
        }
        // Always adjust to the current trip id or else server won't accept it
        DriverRoute.frCounts.tripId = serverVehicle?.fixed_route_trip?._id??-1;
        return DriverRoute.frCounts;
    }

    private onWSPayload( wsPayload:WSPayloadType.WSPayload.Hydrated ) {
        const wsPayloadExisted = !!this.wsPayload;
        this.wsPayload = wsPayload;
        // Selectively force update the components that have initialized.
        // This avoids unnecessary re-renders of the whole page. This is 
        // particularly important because re-rendering the camera component 
        // flickers and does not look nice.
        if( wsPayloadExisted ) {
            if( this.vehicleProps ) {
                // This needs to update on every update from server
                this.vehicleProps.forceUpdate(); 
            }
            if( this.badgeScanner ) {
                // This needs to update only on specific conditions
                if( this.badgeScannerRendered!==this.badgeScanner.needsToRender() ) {
                    this.badgeScanner.forceUpdate();
                    this.badgeScannerRendered = !this.badgeScannerRendered;
                }
            }
            if( this.drivingInstructions ) {
                // This needs to update on every update from server
                this.drivingInstructions.forceUpdate(); 
            }
        }
        else {
            // Have to force update to put many components on the page,
            // such as google map, vehicle props, etc.
            this.forceUpdate();
        }
        if( !this.googleMap )
            return;
        const serverVehicle = wsPayload.vehiclesById[this.state.vehicle?._id??-1];
        if( serverVehicle ) {
            if( !this.mapVehicle ) {
                // @ts-expect-error
                this.mapVehicle = new DriverMapVehicle(this.googleMap,this);
            }
            if( serverVehicle.fixed_route_trip )
                this.mapVehicle.updateFrStop(dayjs(wsPayload.seconds*1000).tz(tectransit.agency.time_zone));
            try {
                if( this.mapVehicle.update(serverVehicle) ) {
                    if( this.vehicleProps?.autoZoom ) {
                        // Note: this will not necessarily get the vehicle into the center of the map
                        this.googleMap!.fitBounds(MapOptions.getLegBounds((serverVehicle.route.legs||[])[0]));
                    }
                }
            }
            catch( err ) {
                console.error(`Cannot update MapVehicle ${serverVehicle._id}:`,err);
            }
        }
        else {
            console.warn(`Cannot find vehicle #${this.state.vehicle?._id||-1} in the WS payload`);
        }
    }
    private onPositionChanged( currentPosition:VehicleType.ReportedLocation ) {
        this.sendToServer({
            type     : 'location',
            ...currentPosition,
        });
        if( this.mapVehicle )
            this.mapVehicle.move(currentPosition);
    }
    private closeWebSocket( reason:string ) {
        if( this.ws ) {
            try {
                this.ws.close(3001,reason);
            }
            catch (err) {
                console.error(`Cannot close websocket`,err);
            }
            this.ws = undefined;
        }
    }
    private getWebsocket( timeout=1000 ) {
        const onClose = () => {
            timeout = Math.min(5*60*1000,timeout*1.1); // slow exponential back-off with 5 mins cut-off
            window.setTimeout(() => {
                // Re-open only if we haven't explicitly closed the socket by calling shutdown()
                if( this.ws )
                    this.ws = this.getWebsocket(timeout);
            },timeout);
        };
        const onData = (data:Record<string,any>) => {
            const dehydrated = data as WSPayloadType.WSPayload.Dehydrated;
            if( WSPayloadType.WSPayload.isValid(dehydrated) ) {
                try {
                    this.onWSPayload(WSPayloadType.WSPayload.hydrate(dehydrated));
                }
                catch( err ) {
                    this.alert.set(`Cannot hydrate WS message: ${(err as Error).message}`);
                }
            }
            else if( data.message ) {
                console.log(`Got a message from server`,data);
                if( data.donotReopen )
                    this.closeWebSocket("do not re-open");
            }
            else {
                console.warn(`Got unrecognized WS payload: `,data);
            }
        };
        const onOpen = () => {
            // start re-connecting with 1 second timeout again
            timeout = 1000;
            // re-send offline messages
            const offlineWSMessages = this.offlineWSMessages;
            this.offlineWSMessages = [];
            offlineWSMessages.forEach( wsMessage => {
                this.sendToServer(wsMessage);
            });
        };
        return tectransit.getWebsocket(`ws/Driver`,onClose,onData,onOpen);
    }
    private onGeoPositionChanged( geo_position:GeolocationPosition ) : void {
        const coords        = geo_position.coords;
        const accuracy      = Math.min(tectransit.agency.gps_accuracy_meters,isNaN(coords.accuracy)?tectransit.agency.gps_accuracy_meters:coords.accuracy);
        const metersBetween = this.currentLatLng ? LatLngType.getMetersBetween(this.currentLatLng,{lat:coords.latitude,lng:coords.longitude}) : Infinity;
        // If geo position has not changed enough and we recently sent geo position, then do nothing
        if( (metersBetween<accuracy) && ((Date.now()-this.lastTimeGeoPositionSent)<(tectransit.agency.max_lateness_seconds*1000)) )
            return;
        this.currentLatLng  = {
            lat      : coords.latitude,
            lng      : coords.longitude,
            altitude : coords.altitude,
            accuracy : coords.accuracy,
            heading  : coords.heading,
            speed    : coords.speed,
            seconds  : Math.round(Date.now()/1000)
        };
        this.onPositionChanged(this.currentLatLng);
        this.lastTimeGeoPositionSent = Date.now();

        // Let's be very smart about how often we call lat lng lookup
        if( this.mapVehicle?.serverVehicle?.fixed_route_trip ) {
            // fixed routes just see the route name and stop as the "address"
            const nextStopStep = this.mapVehicle.serverVehicle.route.legs.map(l=>l.steps).flat().find(s=>s.stop);
            this.latLngAddress = this.mapVehicle.serverVehicle.fixed_route_trip.name+(nextStopStep?` to ${nextStopStep.stop?.name}`:'');
        }
        else {
            // We are on an on-demand trip
            const metersMoved   = this.addressLatLng ? LatLngType.getMetersBetween(this.addressLatLng,this.currentLatLng) : Infinity;
            const secondsPassed = (Date.now()-this.lastLatLngLookupAt)/1000;
            if( metersMoved<10 ) {
                this.logMessage = `the location has changed only by ${metersMoved.toFixed(2)}m`;
            }
            else if( secondsPassed<10 ) {
                this.logMessage = `we called lookup only ${secondsPassed.toFixed(2)}s ago`;
            }
            else {
                this.logMessage = `calling lookup`;
                this.lastLatLngLookupAt = Date.now();
                tectransit.geocoder.getLatlngLookupPromise(this.currentLatLng).then( geocoderResult => {
                    this.logMessage     = `got lookup`;
                    this.addressLatLng  = this.currentLatLng;
                    this.latLngAddress  = geocoderResult.formatted_address;
                });
            }
        }
    }
    private onGeoPositionError( err:GeolocationPositionError ) {
        console.error(`Cannot get geo position`,err);
    }
    // event handlers
    private getOrderApiPromise( attempt:number, order:OrderType.Order, body:Record<string,any> ) : Promise<Record<string,any>> {
        return getApiPromise('/api/driver/order',"PUT",{order_id:order._id},body)
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||`unknown error`);
                console.log(`Order #${order._id} is saved on attempt #${attempt}`,body);
                return res;
            })
            .catch( err => {
                if( attempt>14 ) {
                    // 14 attempts is more than 2 hours
                    this.alert.set( `Cannot save order #${order._id}: ${err.message}, afer ${attempt} attempts, giving up`,5000);
                    delete this.pretendedStatusByOrderId[order._id!];
                    return Promise.reject(err);
                }
                if( attempt<1 ) {
                    this.alert.set( `Cannot save order #${order._id}: ${err.message}, will retry. You can ignore this message`,5000);
                    this.pretendedStatusByOrderId[order._id!] = body.status;
                    // fall through to retry
                }
                return new Promise<void>( resolve => {
                    window.setTimeout( () => {
                        resolve();
                    },5000*(2**attempt));
                }).then( () => {
                    console.log(`Attempt #${attempt} to save order #${order._id}`,body);
                    return this.getOrderApiPromise(attempt+1,order,body);
                });
            });
    }
    private onLogout() {
        this.setState({
            redirectToPti : true
        });
    }
    // Overloads from RouteContainer
    protected getStepAddress( legEvent:LegType.Event, step:LegType.Step ) {
        const justAddress     = super.getStepAddress(legEvent,step);
        const route           = this.mapVehicle?.serverVehicle?.route;
        const firstLegEvent   = (route?.legs||[]).map(l=>[l.pickup,l.dropoff]).flat().find(legEvent=>((legEvent.orders||[]).length>0));
        if( legEvent!==firstLegEvent )
            return justAddress;
        return googleMapsLinks.getDirections(legEvent!.latlng,justAddress);
    }
    protected getStepInstructionsNode( leg:LegType.Leg, step:LegType.Step ) {
        const serverVehicle = this.mapVehicle?.serverVehicle;
        if( !serverVehicle )
            return false;
        const getPickupDropoffButtonsTds = ( order:OrderType.Order ) : React.ReactNode => {
            const paddingStyle = {paddingLeft:'1rem'};
            if( (step.maneuver==='pickup') && this.isOrderPickupable(order) )
                return [
                    (<td key='noshowButton' style={paddingStyle}>
                        <button className="btn-theme btn-small" onClick={(e)=>this.onNoShow(e,order)}>
                            no show
                        </button>
                    </td>),
                    (<td key='pickupButton' style={paddingStyle}>
                        <button className="btn-theme btn-small" onClick={(e)=>this.onPickup(e,order)}>
                            {step.maneuver}
                        </button>
                    </td>)
                ];
            if( (step.maneuver==='dropoff') && this.isOrderDropoffable(order) )
                return (<td key='dropoffButton'>
                    <button className="btn-theme btn-small" onClick={(e)=>this.onDropoff(e,order)}>
                        {step.maneuver}
                    </button>
                </td>)
            return false;
        }
        switch( step.maneuver ) {
        case 'pickup':
        case 'dropoff':
            const legEvent    = leg[step.maneuver];
            const showButtons = tectransit.agency.always_show_stop_buttons||this.isCloseTo(step.start_location||step.end_location||legEvent?.latlng);
            return (<>
                {this.getStepAddress(legEvent,step)}
                {(legEvent.orders||[]).map( (order,ndx) => {
                    return <table key={ndx}>
                        <tbody>
                            <tr>
                                <td key='name'>(#{order._id}) {step.maneuver} {order.user?.name}&nbsp;</td>
                                {showButtons && getPickupDropoffButtonsTds(order)}
                            </tr>
                        </tbody>
                    </table>;
                })}
            </>);
        default:
            return (<div dangerouslySetInnerHTML={{__html:step.html_instructions}}/>);
        }
    }
    // Rendering of different objects
    private googleMapDidMount() {
        try {
            const mapOptions = new MapOptions(MapOptions.getBounds(tectransit.getAgencyBoundary(),0.025));
            this.googleMap = tectransit.getGoogleMap(document.getElementById("map")!,{...mapOptions,zoomControl:false});
            this.mapBoundaries = (tectransit?.agency?.boundaries||[]).map( (boundary,ndx) => {
                return new google.maps.Polyline({
                    strokeColor   : MapVehicle.colors[ndx%MapVehicle.colors.length],
                    strokeOpacity : 1.0,
                    strokeWeight  : 3,
                    map           : this.googleMap,
                    path          : boundary
                });
            });
            this.windowSizeListener = window.addEventListener('resize',() => {
                console.log(`window is resized to ${window.innerWidth}x${window.innerHeight}`);
            });
        }
        catch( err ) {
            this.alert.set(`Initialization error (${(err as Error).message})`);
        }
    }
    // public
    constructor( props:Props ) {
        super(props);
        this.state = {
            splitterDirection    : ((window.innerWidth>window.innerHeight) ? 'horizontal' : 'vertical')
        };
        Promise.all([
            getApiPromise<VehicleType.Vehicle>('/api/driver/vehicle','GET')
                .then( vehicle => {
                    if( !vehicle || vehicle.err )
                        throw Error(vehicle?.err||`vehicle is empty`);
                    this.setState({vehicle});
                })
                .catch( err => {
                    this.alert.set(`Cannot load vehicle (${err.message})`);
                }),
            getApiPromise<DVIRecordType.DVIRecord>('/api/driver/dvi','GET')
                .then( dviRecord => {
                    if( !dviRecord || dviRecord.err )
                        throw Error(dviRecord?.err||`dviRecord is empty`);
                    this.setState({dviRecord});
                })
                .catch( err => {
                    this.alert.set(`Cannot load DVI record (${err.message})`);
                })
        ]);
    }
    componentDidMount() {
        const options           = {maximumAge:5000,enableHighAccuracy:true};
        this.geoPositionWatcher = navigator.geolocation.watchPosition(
            (gp)  => this.onGeoPositionChanged(gp),
            (err) => this.onGeoPositionError(err),
            options
        );
        if( tectransit.agency.fixed_route_enabled ) {
            // Let's start listening for APC device messages
            const onApcData = ( data:any, sendSocket:((data:any)=>Promise<void>), closeSocket:(()=>Promise<void>) ) => {
                const packet = jsonParse(data,{}) as ApcType.Packet;
                if( !packet.sn || !packet.type )
                    throw Error(`Got wrong data from APC: ${data}`);
                const sendAndCloseSocket = (packet:ApcType.Packet) : Promise<void> => {
                    return sendSocket(JSON.stringify({
                        sn      : packet.sn,
                        type    : packet.type,
                        time    : packet.time,
                        data    : {
                            time : packet.time
                        }
                    })).then( () => closeSocket() );
                }
                console.log("Got packet",data);
                switch( packet.type ) {
                    case 'real':
                    case 'count': {
                        const firstCount = (packet as ApcType.Data).data[0]?.count[0];
                        if( typeof firstCount !== 'object' )
                            throw Error(`Got wrong data from APC: ${data}`);
                        const ins       = (Number.isInteger(firstCount.in) ? firstCount.in   : 0);
                        const outs      = (Number.isInteger(firstCount.out) ? firstCount.out : 0);
                        const tripCounts  = this.getTripCounts();
                        tripCounts.apcPassengers += (ins-outs);
                        if( tripCounts.apcPassengers<0 )
                            tripCounts.apcPassengers = 0;
                        tripCounts.apcBoardings  += ins;
                        tripCounts.seconds        = Math.round(Date.now()/1000);
                        this.sendToServer({
                            type        : 'tripCounts',
                            ...tripCounts
                        });
                        sendAndCloseSocket(packet);
                        this.apcState.dataPackets++;
                        this.apcState.totalIns += ins;
                        this.apcState.totalOuts += outs;
                        break;
                    }
                    case 'gps':
                        sendAndCloseSocket(packet);
                        this.apcState.gpsPackets++;
                        break;
                    case 'heart':
                        sendAndCloseSocket(packet);
                        this.apcState.heartbeatPackets++;
                        break;
                    default:
                        this.apcState.unknownPackets++;
                        console.error(`Got unknown packet`,data);
                        break;
                }
            };
            TecTransITPlugin.openTcpPort(ApcType.tcpPort,onApcData)
                .then( openTcpPortResult => {
                    console.log(`Opened TCP port ${ApcType.tcpPort}`,JSON.stringify(openTcpPortResult));
                    this.openTcpPortResult = openTcpPortResult;
                })
                .catch( err => {
                    // So we were not able to open the port. One possible reason for this is that the plugin
                    // has not loaded. It could have not loaded because after the driver login, the capacitor
                    // plugins did not survice the redirection from the /api/auth/login to the main 
                    // application at /.
                    // 
                    // So  ... what do we do? Good news. Given that we already have an authentication then
                    // we can simply reload the page and there won't be any redirects anymore. We need to
                    // be cautious about this because we don't want to fall into reloading loop. So, let's
                    // keep a track of the last reload time and if we've reloaded in the last 5 minutes, 
                    // then just show an error.
                    const lastReload = Number(sessionStorage.getItem('lastCapacitorHackingReload'));
                    if( (Date.now()-lastReload)>(1000*60*5) ) {
                        // If we've reloaded the page in the last 5 minutes, don't keep reloading
                        // Just report an error
                        sessionStorage.setItem('lastCapacitorHackingReload',String(Date.now()));
                        window.location.reload();
                    }
                    else {
                        this.apcMessage = `Cannot open TCP port ${ApcType.tcpPort} ${err.message}`;
                    }
                });
            TecTransITPlugin.buildConfig()
                .then( buildConfig => {
                    console.log('buildConfig',JSON.stringify(buildConfig));
                    this.pluginBuildConfig = buildConfig;
                })
                .catch( err => {
                    this.apcMessage = `Cannot get build config ${err.message}`;
                });
        }
    }
    componentWillUnmount() {
        if( this.openTcpPortResult ) {
            TecTransITPlugin.closeTcpPort(this.openTcpPortResult)
                .then( res => {
                    console.log(`Closed TCP port ${ApcType.tcpPort}`,res);
                })
                .catch( err => {
                    console.error(`Cannot close TCP port ${ApcType.tcpPort}`,err);
                });
            this.openTcpPortResult = undefined;
        }
        if( this.windowSizeListener ) {
            this.windowSizeListener.remove();
            this.windowSizeListener = undefined;
        }
        if( this.geoPositionWatcher ) {
            navigator.geolocation.clearWatch(this.geoPositionWatcher);
            this.geoPositionWatcher = undefined;
        }
        this.closeWebSocket("component unmounts");
    }
    render() {
        return (
            <MenuItem.MenuItem title="Route" logoutTitle={'Post Trip Inspection'} onLogout={tectransit.agency.skip_ptis?undefined:(()=>this.onLogout())}>
                {MenuItem.withAlert((alert) => {
                    this.alert = alert;
                    // Make sure that once we initialized google map, we do not erase it anymore
                    if( this.alert.message && (this.googleMapRenderings<1) )
                        return;
                    if( !this.state.vehicle )
                        return (<div style={{textAlign:'center'}}>Loading vehicle...</div>);
                    if( !this.state.dviRecord )
                        return (<div style={{textAlign:'center'}}>Loading DVI...</div>);

                    if( !tectransit.agency.skip_ptis && this.state.redirectToPti )
                        return (<Navigate to={'/Driver/dvi/posttrip'} state={{dviRecord:this.state.dviRecord,vehicle:this.state.vehicle}} replace/>);
                    if( !tectransit.agency.skip_dvis && !DVIRecordType.isAcceptable(this.state.dviRecord,tectransit.user._id,dayjs().tz(tectransit.agency.time_zone).startOf('day').valueOf()) )
                        return (<Navigate to={'/Driver/dvi/pretrip'} state={{dviRecord:this.state.dviRecord,vehicle:this.state.vehicle}} replace/>);

                    if( !this.ws )
                        this.ws = this.getWebsocket();
                    if( !this.wsPayload )
                        return (<div style={{textAlign:'center'}}>Connecting to server...</div>);

                    const currentLegsCount = this.mapVehicle?.serverVehicle.route.legs?.length||0;
                    if( this.playedLegsCount!==currentLegsCount ) {
                        (new Audio('/chime.mp3'))
                            .play()
                            .then(() => {
                                this.playedLegsCount = currentLegsCount;
                            })
                            .catch( err => {
                                console.error(`To hear a chime when there is a change, please dismiss this message (${err.message})`);
                                this.alert.set(`To hear a chime when there is a change, please dismiss this message`);
                            });
                    }
                    // Note:
                    // I also tried to window resize events and re-render the component to make sure
                    // that instructions and map always take the full size. There was no problem with
                    // instructions. But the Google maps component does not like dynamic resizing and
                    // becomes completely blank on an attempt to change height/width
                    const divs             = [
                        <div key={`instructions${this.state.splitterDirection}`} className="wrapper" style={{overflowY:'auto'}}>
                            <table width="100%">
                                <tbody>
                                {[
                                    <VehicleProps key={'VehicleProps'} driverRoute={this} ref={ (vp) => {
                                        this.vehicleProps = vp;
                                    }}/>,
                                    <BadgeScanner key={'BadgeScanner'} driverRoute={this} ref={ (bs) => {
                                            this.badgeScanner = bs;
                                    }}/>,
                                    <DrivingInstructions key={'DrivingInstructions'} driverRoute={this} ref={ (di) => {
                                        this.drivingInstructions = di;
                                    }}/>
                                ]}
                                </tbody>
                            </table>
                        </div>,
                        <div key={`map${this.state.splitterDirection}`} id="map" className="map-card"/>
                    ];
                    const minSize          = 100;
                    const gutterSize       = 14;
                    const splitterHeight = window.innerHeight-MenuItem.height;
                    const splitterWidth  = window.innerWidth;
                    const flexStyle      = (this.state.splitterDirection==='vertical') ? {} : {display:'flex',borderSizing:'border-box'};
                    this.googleMapRenderings++;
                    if( this.googleMapRenderings===1 ) {
                        setImmediate(() => {
                            this.googleMapDidMount();
                        });
                    }
                    return (
                        <Split
                            className   = "split"
                            key         = {`splitter${this.state.splitterDirection}`}
                            sizes       = {[40,60]}
                            minSize     = {[minSize,minSize]}
                            direction   = {this.state.splitterDirection}
                            gutterSize  = {gutterSize}
                            style       = {{width:`${splitterWidth}px`,height:`${splitterHeight}px`,...flexStyle}}
                            elementStyle= {(dimension, elementSize, gutterSize, index) => {
                                if( isNaN(elementSize) )
                                    return {};
                                const size  = (((this.state.splitterDirection==='vertical')?splitterHeight:splitterWidth)-gutterSize)*(elementSize/100);
                                const style = (this.state.splitterDirection==='vertical') ? {
                                    height : `${size}px`,
                                    width  : `${splitterWidth}px`
                                } : {
                                    flex   : `${elementSize}%`,
                                    height : `${splitterHeight}px`,
                                    width  : `${size}px`
                                };
                                return style;
                            }}
                        >
                            {divs}
                        </Split>
                    );
                })}
            </MenuItem.MenuItem>
        );
    }
}
