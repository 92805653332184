export interface Packet {
    sn          : string;
    type        : string;
    time        : number;
}
export interface Data extends Packet {
    data        : {
        iCreatedTime    : number;
        id              : 1;
        iLongitude      : number;
        iLatitude       : number;
        count           : {
            name        : string;
            in          : number;
            out         : number;
            real?       : number;
            //allin       : number;
            //allout      : number;
            //ip          : number;
            //op          : number;
            //real        : number;
        }[]
    }[];
}

export interface Heartbeat extends Packet {
    data        : {
        iCreatedTime    : number;
    }[];
}

export interface UploadResponse {
    sn          : string;
    type        : string;
    time        : number;
}


export const tcpPort       = 44544;
