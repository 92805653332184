import React, {
    useState }             from 'react';

import * as images         from 'images/';
import Alert               from 'utils/Alert';
import tectransit          from 'utils/TecTransit';
import ErrorBoundary       from 'components/ErrorBoundary';
import HeaderAlertContext  from 'contexts/HeaderAlertContext';

const timesStyle = {
    position        : 'absolute' as ('absolute'|'relative'|undefined),
    width           : '36px',
    height          : '30px',
    right           : '0px',
    top             : '8px',
    border          : 'none',
    background      : 'none',
    color           : '#3e4676'
};

const backgroundByRole = {
    'Passenger'     : '#3e4676',
    'Driver'        : '#3e7c87',
    'Maintenance'   : '#3e876c',
    'Dispatcher'    : '#80873e',
    'Manager'       : '#875b3e',
    'Observer'      : '#873e3e',
} as Record<string,string>;

export const height = 60;

export interface Props {
    title       : string|React.ReactNode;
    background? : string;
    children?   : React.ReactNode;
    logoutTitle?: string;
    onLogout?   : () => void;
}

export const MenuItem = ( props:Props ) => {
    const [alertMessage,setAlertMessage] = useState<string>('');
    class HeaderAlert extends Alert {
        set( message?:string, timeout?:number ) : Alert {
            // Carefully update alert to avoid the following nightmare screnario:
            // 1/ An component uses HeaderAlertContext and does rendering
            // 2/ An error happens during the render and alert is set
            // 3/ Setting alert changes the state of </MenuItem>
            // 4/ This causes the <MenuItem/> to re-render and re-render all the children
            // 5/ The component from step 1/ re-denred and sets alert again
            // 6/ We fall into an endless loop of re-renderings
            if( this.message!==message ) {
                this.message = message;
                if( message ) {
                    // Setup cleaning up after timeout if was requested
                    if( timeout )
                        window.setTimeout(()=>setAlertMessage(''),timeout);
                }
                setAlertMessage(this.message||''); // and re-render
            }
            return this;
        }
    };
    const roleBackground = backgroundByRole[window.location.href.replace(/^https?:\/\/[^/]+\/([^/]+)\/.+$/,"$1")]
        || backgroundByRole[tectransit?.user?.roles[0]]
        || backgroundByRole['Passenger'];
    const [headerStyle, setHeaderStyle] = React.useState<any>({});
    const [alertMessageStyle, setAlertMessageStyle] = React.useState<any>({});
    React.useEffect(()=>{
        const widthStyles  = {
            width: tectransit.menu.isOpen ? "calc(100% - 300px)" : "100%"
        };
        setHeaderStyle({
            top : "0px",
            height:`${height}px`,
            background:(props.background||roleBackground),
            zIndex:999,
            ...widthStyles
        });
        setAlertMessageStyle({
            top:`${height}px`,
            zIndex:999,
            ...widthStyles
        });
    },[roleBackground,props.background]);

    return (
        <ErrorBoundary>
            <HeaderAlertContext.Provider value={new HeaderAlert(alertMessage)}>
            <div className="header" style={headerStyle}>
                <span className="header__brand">{tectransit.agency?.name||'TECTRANSIT'}</span>
                <span className="header__title">{props.title}</span>
                <span className="header__logout"
                    title       = {props.logoutTitle||"log out"}
                    style       = {{
                        cursor : 'pointer'
                    }}
                    onClick     = {(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if( typeof props.onLogout === 'function' )
                            return props.onLogout();
                        window.location.href = '/auth/logout';
                    }}
                >
                    <images.Logout/>
                </span>
            </div>
            { alertMessage && (
                <div className="alert-message" style={alertMessageStyle}>
                    <div className="alert-message__inner">
                        <images.Alert/>
                        <span>{alertMessage}</span>
                    </div>
                    <button style={timesStyle} className="close" aria-label="Close" onClick={()=>{setAlertMessage('')}}>
                        <images.Close/>
                    </button>
                </div>
            )}
            <div style={{paddingTop:alertMessage?`calc(${height}px + 3rem)`:`${height}px`}}>
                {props.children}
            </div>
            </HeaderAlertContext.Provider>
        </ErrorBoundary>
    );
}

export const withAlert = ( proc:(alert:Alert)=>(string|React.ReactNode) ) : React.ReactElement => {
    return (<HeaderAlertContext.Consumer>{(alert:Alert)=>proc(alert)}</HeaderAlertContext.Consumer>);
};

export const withMenuItem = ( title:(string|React.ReactNode), proc:(alert:Alert)=>(string|React.ReactNode) ) : React.ReactElement => {
    return (<MenuItem title={title}>{withAlert(proc)}</MenuItem>);
};

export default MenuItem;