import * as OrderType           from './Order';
import * as UserType            from './User';
import * as LegType             from './Leg';
import * as LatLngType          from './LatLng';
import * as VehicleType         from './Vehicle';
import * as FixedRouteType      from './FixedRoute';
import rehash                   from '../utils/rehash';
import hashById                 from '../utils/hashById';

export namespace Step {
    export interface Hydrated extends LegType.Step {
    }
    export type Dehydrated = [
        [number,number],
        [number,number],
        [number,string],
        [number,string],
        string,
        { points : string },
        string?,
        string?,
        [ // FixedRouteType.Stop
            number,
            string,
            number,
            number,
            [ // FixedRouteType.StopTime
                number,
                number,
                string,
                string,
                number,
                number
            ]
        ]?
    ];
    export const dehydrate = ( step:Hydrated ) : Dehydrated => {
        return [
            [step.start_location.lat,step.start_location.lng],
            [step.end_location.lat,step.end_location.lng],
            [step.duration.value,step.duration.text],
            [step.distance.value,step.distance.text],
            step.html_instructions,
            step.polyline,
            step.maneuver,
            step.copyrights,
            // no need for `original_duration` because it is internal server thing
            step.stop ? [
                step.stop._id,
                step.stop.name,
                step.stop.lat,
                step.stop.lng,
                [
                    step.stop.stop_time.trip_id,
                    step.stop.stop_time.stop_id,
                    step.stop.stop_time.arrival_time,
                    step.stop.stop_time.departure_time,
                    step.stop.stop_time.stop_sequence,
                    step.stop.stop_time.shape_dist_traveled
                ]
            ] : undefined
        ];
    }
    export const hydrate = ( step:Dehydrated ) : Hydrated => {
        return {
            start_location     : {lat:step[0][0],lng:step[0][1]},
            end_location       : {lat:step[1][0],lng:step[1][1]},
            duration           : {value:step[2][0],text:step[2][1]},
            distance           : {value:step[3][0],text:step[3][1]},
            html_instructions  : step[4],
            polyline           : step[5],
            maneuver           : step[6],
            copyrights         : step[7],
            stop               : step[8] ? {
                _id         : step[8][0],
                name        : step[8][1],
                lat         : step[8][2],
                lng         : step[8][3],
                stop_time   : {
                    trip_id             : step[8][4][0],
                    stop_id             : step[8][4][1],
                    arrival_time        : step[8][4][2],
                    departure_time      : step[8][4][3],
                    stop_sequence       : step[8][4][4],
                    shape_dist_traveled : step[8][4][5]
                }
            } : undefined
        };
    }
}
export namespace LegEvent {
    export interface Hydrated<T extends OrderType.Order=OrderType.Order> extends LegType.Event<T> {
    }
    export type Dehydrated = [
        [number,number],
        string?,
        number[]?,
        number?
    ];
    export const dehydrate = <T extends OrderType.Order=OrderType.Order>( event:Hydrated<T>,ordersById:Record<number,T> ) : Dehydrated => {
        return [
            [event.latlng.lat,event.latlng.lng],
            event.address,
            event.orders?.filter(o=>!isNaN(o._id!)).map( o => {
                ordersById[o._id!] = o;
                return o._id!;
            }),
            event.seconds
        ];
    }
    export const hydrate = <T extends OrderType.Order=OrderType.Order>( event:Dehydrated, ordersById:Record<string,T> ) : Hydrated<T> => {
        return {
            latlng    : {lat:event[0][0],lng:event[0][1]},
            address   : event[1],
            orders    : event[2]?.map(id=>ordersById[id]),
            seconds   : event[3]
        };
    }
}
export namespace Leg {
    export interface Hydrated extends LegType.Leg {
    }
    export type Dehydrated = [
        Step.Dehydrated[],
        [number,string]|undefined,
        [number,string]|undefined,
        LegEvent.Dehydrated,
        LegEvent.Dehydrated,
    ]
    export const dehydrate = ( leg:Hydrated, ordersById:Record<number,OrderType.Order>  ) : Dehydrated => {
        return [
            leg.steps.map(Step.dehydrate),
            leg.duration ? [leg.duration.value,leg.duration.text] : undefined,
            leg.distance ? [leg.distance.value,leg.distance.text] : undefined,
            LegEvent.dehydrate(leg.pickup,ordersById),
            LegEvent.dehydrate(leg.dropoff,ordersById)
        ];
    }
    export const hydrate = ( leg:Dehydrated, ordersById:Record<string,OrderType.Order> ) : Hydrated => {
        return {
            steps       : leg[0].map(Step.hydrate),
            duration    : leg[1] ? {value:leg[1][0],text:leg[1][1]} : undefined,
            distance    : leg[2] ? {value:leg[2][0],text:leg[2][1]} : undefined,
            pickup      : LegEvent.hydrate(leg[3],ordersById),
            dropoff     : LegEvent.hydrate(leg[4],ordersById)
        };
    }
}
export namespace Route {
    export interface Hydrated<T extends (OrderType.OrderTypes|OrderType.IdTypes)=OrderType.Order<number>> {
        legs               : LegType.Leg<LegType.Event<T>>[];
        waypoint_order?    : number[];
        summary?           : string;
        last_locations?    : (LatLngType.LatLng & { seconds:number })[];
    }
    export type Dehydrated = [
        Leg.Dehydrated[],
        number[]?,
        string?,
        [number,number,number][]?
    ]
    export const dehydrate = ( route:Hydrated, wantLegsWithoutOrders:boolean, ordersById:Record<number,OrderType.Order> ) : Dehydrated => {
        return [
            (wantLegsWithoutOrders?route.legs:route.legs.slice(0,route.legs.findLastIndex(l=>{
                return (l.pickup.orders?.length||0)+(l.dropoff.orders?.length||0)>0;
            })+1)).map(leg=>Leg.dehydrate(leg,ordersById)),
            route.waypoint_order,
            route.summary,
            route.last_locations?.map(l=>[l.lat,l.lng,l.seconds])
        ];
    }
    export const hydrate = ( route:Dehydrated, ordersById:Record<string,OrderType.Order> ) : Hydrated => {
        return {
            legs            : route[0].map(leg=>Leg.hydrate(leg,ordersById)),
            waypoint_order  : route[1],
            summary         : route[2],
            last_locations  : route[3]?.map(l=>({lat:l[0],lng:l[1],seconds:l[2]}))
        };
    }
}
export namespace Vehicle {
    export interface Hydrated extends Omit<VehicleType.Vehicle,'fixed_route_trip'> {
        fixed_route_trip?  : FixedRouteType.DBTrip;
    }
    export type Dehydrated = [
        VehicleType.Type,
        number,
        number,
        Route.Dehydrated,
        number,
        (VehicleType.ReportedLocation & { seconds:number })?,
        number?,
        number?,
        number?,
        number?,
        number?,
        string?,
        number?,
        number?,
        string?,
        string?,
        string?,
        string?,
        string?,
        VehicleType.TripStats?,
        number?,
        boolean?,
        boolean?,
        number?,
        string?,
        FixedRouteType.DBTrip?,
        FixedRouteType.TripCounts?
    ]
    export const dehydrate = ( vehicle:Readonly<Vehicle.Hydrated>, wantLegsWithoutOrders:boolean, ordersById:Record<number,OrderType.Order> ) : Dehydrated => {
        return [
            vehicle.type,
            vehicle._id,
            vehicle.agency_id,
            Route.dehydrate(vehicle.route,wantLegsWithoutOrders,ordersById),
            vehicle.odometer_meters,
            vehicle.reported_location,
            vehicle.idling_since_ms,
            vehicle.moving_since_ms,
            vehicle.route_set_seconds,
            vehicle.reported_driverid,
            vehicle.driverid,
            vehicle.depot_name,
            vehicle.seats,
            vehicle.wheelchairs,
            vehicle.license_plate,
            vehicle.license_state,
            vehicle.make_model_year,
            vehicle.vin_number,
            vehicle.unavailability_reason,
            vehicle.day_trip_stats,
            vehicle.archived_at,
            vehicle.on_demand_enabled,
            vehicle.is_online,
            vehicle.is_online_changed_at,
            vehicle.fixed_route_name,
            !vehicle.fixed_route_trip ? undefined : {
                // Serialize only "known" fields
                _id             : vehicle.fixed_route_trip._id,
                service_id      : vehicle.fixed_route_trip.service_id,
                shape_id        : vehicle.fixed_route_trip.shape_id,
                name            : vehicle.fixed_route_trip.name,
                route_name      : vehicle.fixed_route_trip.route_name
            },
            vehicle.fixed_route_trip_counts
        ];
    }
    export const hydrate = ( vehicle:Readonly<Vehicle.Dehydrated>, ordersById:Record<string,OrderType.Order> ) : Vehicle.Hydrated => {
        return {
            type                    : vehicle[0],
            _id                     : vehicle[1],
            agency_id               : vehicle[2],
            route                   : Route.hydrate(vehicle[3],ordersById),
            odometer_meters         : vehicle[4],
            reported_location       : vehicle[5],
            idling_since_ms         : vehicle[6],
            moving_since_ms         : vehicle[7],
            route_set_seconds       : vehicle[8],
            reported_driverid       : vehicle[9],
            driverid                : vehicle[10],
            depot_name              : vehicle[11],
            seats                   : vehicle[12],
            wheelchairs             : vehicle[13],
            license_plate           : vehicle[14],
            license_state           : vehicle[15],
            make_model_year         : vehicle[16],
            vin_number              : vehicle[17],
            unavailability_reason   : vehicle[18],
            day_trip_stats          : vehicle[19],
            archived_at             : vehicle[20],
            on_demand_enabled       : vehicle[21],
            is_online               : vehicle[22],
            is_online_changed_at    : vehicle[23],
            fixed_route_name        : vehicle[24],
            fixed_route_trip        : vehicle[25],
            fixed_route_trip_counts : vehicle[26]
        };
    }
}

export namespace WSPayload {
    export interface Hydrated {
        seconds      : number;
        vehiclesById : Record<number,Vehicle.Hydrated>;
        ordersById   : Record<string,OrderType.Hydrated<OrderType.Order<number>>>;
        usersById    : Record<number,UserType.User>;
    }
    export interface Dehydrated extends Omit<Hydrated,'vehiclesById'|'ordersById'|'usersById'> {
        vehiclesById : Record<number,Vehicle.Dehydrated>;
        ordersById   : Record<string,OrderType.Dehydrated<number>>;
        usersById    : Record<number,UserType.Dehydrated>;
    }
    export const isValid = ( wsPayload:(Hydrated|Dehydrated) ) : boolean => {
        if( !wsPayload )
            return false;
        if( typeof wsPayload !== 'object' )
            return false;
        if( typeof wsPayload.seconds !== 'number' )
            return false;
        if( typeof wsPayload.vehiclesById !== 'object' )
            return false;
        if( typeof wsPayload.ordersById !== 'object' )
            return false;
        return true;
    }
    export const dehydrate = ( seconds:number, vehicles:Vehicle.Hydrated[], wantLegsWithoutOrders=false ) : Dehydrated => {
        const ordersById    = {} as Record<number,OrderType.Order>;
        const usersById     = {} as Record<number,UserType.Dehydrated>;
        return {
            seconds,
            vehiclesById : hashById(vehicles,vehicle=>Vehicle.dehydrate(vehicle,wantLegsWithoutOrders,ordersById)),
            ordersById   : rehash(ordersById,order=>OrderType.dehydrate(order,usersById)),
            usersById
        };
    }
    export const hydrate = ( wsPayload:Dehydrated ) : Hydrated => {
        const usersById  = rehash(wsPayload.usersById,user=>UserType.hydrate(user))
        const ordersById = rehash(wsPayload.ordersById,order=>OrderType.hydrate(order,usersById));
        return {
            seconds      : wsPayload.seconds,
            vehiclesById : rehash(wsPayload.vehiclesById,vehicle=>Vehicle.hydrate(vehicle,ordersById)),
            ordersById   : ordersById,
            usersById    : usersById
        };
    }
}

export interface WSMessage {
    type        : string;
    seconds     : number;
    [x:string]  : any;
}
