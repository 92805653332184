import React                from 'react';

import * as AgencyType      from 'shared/types/Agency';
import * as DepotType       from 'shared/types/Depot';
import * as WSPayloadType   from 'shared/types/WSPayload';
import dayjs                from 'shared/utils/day-timezone';

import * as images          from 'images/';
import tectransit           from 'utils/TecTransit';
import getApiPromise        from 'utils/getApiPromise';
import Alert                from 'utils/Alert';
import * as MenuItem        from 'components/MenuItem';
import * as googleMapsLinks from 'utils/googleMapsLinks';

export interface DepotEtc extends DepotType.Depot {
    backendName? : string;
}

export interface Props {
}

export default class Settings extends React.Component {

    private alert               : Alert = new Alert();
    private boundariesPolygon   : google.maps.Polygon;

    // @ts-expect-error
    public props : Props;
    public state : {
        depotName    : string,
        depotsByName : Record<string,DepotEtc>
    };

    constructor( props:Props ) {
        super(props);
        this.boundariesPolygon = new google.maps.Polygon({paths:(tectransit.agency.boundaries||[])});
        this.state = {
            depotName    : "",
            depotsByName : tectransit.agency.depots.reduce( (acc,depot) => {
                acc[depot.name.toLowerCase()] = {
                    ...depot,
                    backendName : depot.name
                };
                return acc;
            }, {} as Record<string,DepotEtc> )
        };
    }

    private deleteDepotByName( name:string ) : Record<string,DepotEtc> {
        const result = {
            ...this.state.depotsByName
        };
        delete result[name?.toLowerCase()||''];
        return result;
    }

    // Action handlers
    onSaveAgency( e:any, agency:Partial<AgencyType.Agency> ) {
        e.preventDefault();
        e.stopPropagation();
        getApiPromise<WSPayloadType.WSPayload.Dehydrated>('/api/manager/vehicles','GET')
            .then( wsPayload => {
                if( !wsPayload || wsPayload.err )
                    throw Error(`Cannot get vehicles (${wsPayload?.err||'unknown error'})`);
                const depotsByBackendName = Object.values(this.state.depotsByName).reduce( (acc,depot) => {
                    acc[depot.backendName?.toLowerCase()||''] = depot;
                    return acc;
                 },{} as Record<string,DepotEtc>);
                // Ideally istead of `vehicleUpdates` has we could have created a direct array of promises
                // to update vehicle depots. The problem with this approach is that once a promise is created
                // it cannot be stopped. And we can have a situation where we first create a promise for a
                // vehicle update and then later find that there is a missing depot and the whole transaction
                // is impossible.
                // Long story short: accumulating an has of vehicle updates makes the entire change atomic.
                return Object.values(WSPayloadType.WSPayload.hydrate(wsPayload).vehiclesById).reduce( (vehicleUpdates,vehicle) => {
                    const depot = depotsByBackendName[vehicle.depot_name?.toLowerCase()||''];
                    if( !depot ) {
                        if( !this.state.depotsByName[vehicle.depot_name?.toLowerCase()||''] )
                            throw Error(`Vehicle #${vehicle._id} has depot '${vehicle.depot_name}' but this depot is not known`);
                    }
                    else {
                        if( depot.backendName?.toLowerCase()!==depot.name.toLowerCase() ) {
                            vehicleUpdates[vehicle._id] = depot.name;
                        }
                    }
                    return vehicleUpdates;
                },{} as Record<number,string>);
            })
            .then( vehicleUpdates => {
                return Promise.all([
                    // Save the agency itself
                    getApiPromise('/api/manager/agency','PUT',agency).then(res => {
                        if (!res || res.err)
                            throw Error(`Cannot save agency ${res?.err||'unknown error'}`);
                        return `agency is updated`;
                    }),
                    // Update the vehicles where depots were renamed
                    ...Object.entries(vehicleUpdates).map( ([vehicle_id,depot_name]) => {
                        return getApiPromise('/api/manager/vehicle', 'POST', { vehicle_id: Number(vehicle_id), depot_name }).then(res => {
                            if (!res || res.err)
                                throw Error(`Cannot update vehicle #${vehicle_id} (${res?.err || 'unknown error'})`);
                            return `On vehicle #${vehicle_id} renamed depot to '${depot_name}'`;
                        });
                    })
                ])
            })
            .then( res => {
                tectransit.agency = agency;
                console.log(`Agency is saved`,res);
                this.alert.set(`Agency is saved`,10000);
            })
            .catch( err => {
                this.alert.set(`Cannot save agency (${err.message})`);
            })
    };
    onSaveDepot( depot:DepotType.Depot, originalDepot:(DepotEtc|undefined) ) {
        let result = '';
        if( (result=DepotType.isValid(depot))!=='' ) {
            this.alert.set(`Depot is invalid (${result})`);
            return;
        }
        if( !google.maps.geometry.poly.containsLocation(depot,this.boundariesPolygon) ) {
            this.alert.set(`none of ${tectransit.agency.boundaries?.length||0} agency boundaries contain this depot`);
            return;
        }
        if( !originalDepot ) {
            // This a new depot added
            const existingDepot = this.state.depotsByName[depot.name.toLowerCase()];
            if( existingDepot ) {
                this.alert.set(`Depot with name '${depot.name}' already exists`);
                return;
            }
            this.setState({
                depotName    : "",
                depotsByName : {
                    ...this.state.depotsByName,
                    [depot.name.toLowerCase()] : depot
                }
            });
        }
        else {
            this.setState({
                depotName   : "",
                depotsByName : {
                    ...this.deleteDepotByName(originalDepot.name),
                    [depot.name.toLowerCase()] : {
                        ...depot,
                        backendName : originalDepot.backendName
                    }
                }
            })
        }
    };
    onDeleteDepot( depot:DepotType.Depot ) {
        this.setState({
            depotName     : "",
            depotsByName  : this.deleteDepotByName(depot.name)
        });
    }

    //
    getEditDepotForm( originalDepot:(DepotType.Depot|undefined) ) {
        return (
            <React.Fragment>
                <div className="setting">
                    <div className="setting__item">
                        <div className="setting__name">Depot Name</div>
                        <div className="setting__content">
                            <input
                                type="text"
                                key={`name_${originalDepot?.name||Date.now()}`}
                                className='input-theme input-theme-min'
                                id="depot_name"
                                defaultValue={originalDepot?.name || ''} />
                        </div>
                    </div>
                    <div className="setting__item">
                        <div className="setting__name">Lat</div>
                        <div className="setting__content">
                            <input
                                type="text"
                                key={`lat_${originalDepot?.name||Date.now()}`}
                                className='input-theme input-theme-min'
                                id="depot_lat"
                                defaultValue={originalDepot?.lat || ''} />
                        </div>
                    </div>
                    <div className="setting__item">
                        <div className="setting__name">Lng</div>
                        <div className="setting__content">
                            <input
                                type="text"
                                key={`lng_${originalDepot?.name || Date.now()}`}
                                className='input-theme input-theme-min'
                                id="depot_lng"
                                defaultValue={originalDepot?.lng || ''} />
                        </div>
                    </div>
                </div>
                <table style={{width:"100%"}}>
                    <tbody>
                        <tr>
                            <td>
                                <button
                                    className="btn-theme btn-add"
                                    key={`save_${originalDepot?.name || Date.now()}`}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const newDepot: DepotType.Depot = {
                                            name    : String((document.getElementById(`depot_name`) as HTMLInputElement).value),
                                            lat     : Number((document.getElementById("depot_lat") as HTMLInputElement).value || 'NaN'),
                                            lng     : Number((document.getElementById(`depot_lng`) as HTMLInputElement).value || 'NaN')
                                        };
                                        this.onSaveDepot(newDepot,originalDepot);
                                    }}
                                >
                                    {originalDepot ? "Update" : "Add"}
                                </button>
                            </td>
                            <td>
                                {originalDepot && (
                                    <button
                                        className="btn-theme btn-delete"
                                        key={`delete_${originalDepot?.name || Date.now()}`}
                                        onClick={() => this.onDeleteDepot(originalDepot)}>
                                        Delete
                                    </button>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
    // rendering
    render() {
        return MenuItem.withMenuItem("Etc Settings",( alert_ ) => {
            this.alert = alert_;
            return (
                <div className="content">
                    <div className="wrapper wrap-column">
                        <div className="section-card-inner w-3">
                            <div className="section-card top-1">
                                <div className="section-card__header">
                                    <div className="section-card__title">Agency Settings</div>
                                </div>
                                <div className="setting">
                                    <div className="setting__item">
                                        <div className="setting__name">ID</div>
                                        <div className="setting__content">
                                            {tectransit.agency._id}
                                        </div>
                                    </div>
                                    <div className="setting__item">
                                        <div className="setting__name">Startup Time</div>
                                        <div className="setting__content">
                                            {dayjs(tectransit.agency.startup_ms).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-card-inner w-3">
                            <div className="section-card top-1">
                                <div className="section-card__header">
                                    <div className="section-card__title">General</div>
                                </div>
                                <div className="setting">
                                    <div className="setting__item">
                                        <div className="setting__name">Agency Name</div>
                                        <div className="setting__content">
                                            <input
                                                type="text"
                                                className='input-theme input-theme-min'
                                                id="name"
                                                defaultValue={tectransit.agency.name}/>
                                        </div>
                                    </div>
                                    <div className="setting__item">
                                        <div className="setting__name">Time Zone</div>
                                        <div className="setting__content">
                                            <select className="input-theme input-theme-min" id="time_zone" defaultValue={tectransit.agency.time_zone}>
                                                {Intl.supportedValuesOf('timeZone').map(v=>(<option key={v} value={v}>{v}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="setting__item">
                                        <div className="setting__name">Operator Phone #</div>
                                        <div className="setting__content">
                                            <input
                                                type="text"
                                                className='input-theme input-theme-min'
                                                id="phone_number"
                                                defaultValue={tectransit.agency.phone_number}/>
                                        </div>
                                    </div>
                                    { tectransit.agency.on_demand_enabled && (<>
                                        <div className="setting__item">
                                            <div className="setting__name">Max order duration (min)</div>
                                            <div className="setting__content">
                                                <input
                                                    type="text"
                                                    className='input-theme input-theme-min'
                                                    id="max_order_duration_mins"
                                                    defaultValue={tectransit.agency.max_order_duration_mins}/>
                                            </div>
                                        </div>
                                        <div className="setting__item">
                                            <div className="setting__name">Max Lateness (sec)</div>
                                            <div className="setting__content">
                                                <input
                                                    type="text"
                                                    className='input-theme input-theme-min'
                                                    id="max_lateness_seconds"
                                                    defaultValue={tectransit.agency.max_lateness_seconds}/>
                                            </div>
                                        </div>
                                        <div className="setting__item">
                                            <div className="setting__name"
                                                title="This is how far ahead you want passengers to place the non ASAP orders. Non ASAP orders are orders with specific pickup or dropoff time. If this is 0 then the passengers can place non ASAP starting from the next agencyt business day. Or you can allow passengers to place non ASAP orders at least 48 hours ahead.">
                                                Order Reservation Advance (hours)
                                            </div>
                                            <div className="setting__content">
                                                <input
                                                    type="text"
                                                    className='input-theme input-theme-min'
                                                    id="order_modal_start_ahead_hours"
                                                    defaultValue={Math.round((tectransit.agency.order_modal_start_ahead_mins||0)/60)} />
                                            </div>
                                        </div>
                                    </>) }
                                </div>
                            </div>
                        </div>
                        <div className="section-card-inner w-3">
                            <div className="section-card top-1">
                                <div className="section-card__header">
                                    <div className="section-card__title">Depots</div>
                                </div>
                                <table className='setting' style={{width:"100%"}}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Lat</th>
                                            <th>Lng</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(this.state.depotsByName).map( depot => (
                                            <tr key={depot.name}>
                                                <td className="setting__depot">
                                                    {googleMapsLinks.getPlace(depot,depot.name)}
                                                </td>
                                                <td className="setting__depot">
                                                    {depot.lat.toFixed(6)}
                                                </td>
                                                <td className="setting__depot">
                                                    {depot.lng.toFixed(6)}
                                                </td>
                                                <td className="setting__depot">
                                                    <button onClick={()=>this.setState({depotName:depot.name.toLowerCase()})}>
                                                        <images.EditDepot/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {this.getEditDepotForm(this.state.depotsByName[this.state.depotName])}
                            </div>
                        </div>
                        <div className="section-card-inner w-3">
                            <div className="section-card top-1">
                                <div className="section-card__header">
                                    <div className="section-card__title">Vehicle Selection</div>
                                </div>
                                <div className="setting">
                                    <div className="setting__item">
                                        <div className="setting__name">Vehicle Shift Duration</div>
                                        <div className="setting__content">
                                            <select className="input-theme input-theme-min" id="shift_duration_mins" defaultValue={tectransit.agency.shift_duration_mins}>
                                                {Array(12).fill(0).map((e,ndx)=>(60*(ndx+1))).map(v=>(<option key={v} value={v}>{v/60}</option>))}
                                            </select>
                                            <span>hours. Vehicle will not be selected if it is already driving longer than this time.</span>
                                        </div>
                                    </div>
                                    <div className="setting__item">
                                        <div className="setting__name">Shift Break Duration</div>
                                        <div className="setting__content">
                                            <select className="input-theme input-theme-min" id="break_duration_mins" defaultValue={tectransit.agency.break_duration_mins}>
                                                {Array(8).fill(0).map((e,ndx)=>(15*(ndx+1))).map(v=>(<option key={v} value={v}>{v}</option>))}
                                            </select>
                                            <span>minutes. Vehicle will not be selected unless this much has passed since the break start</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrap">
                        <button className="btn-theme" onClick={e=>this.onSaveAgency(e,{
                            name                          : (document.getElementById(`name`) as HTMLInputElement).value,
                            time_zone                     : (document.getElementById("time_zone") as HTMLSelectElement).value,
                            phone_number                  : (document.getElementById(`phone_number`) as HTMLInputElement).value,
                            shift_duration_mins           : Number((document.getElementById("shift_duration_mins") as HTMLSelectElement).value),
                            break_duration_mins           : Number((document.getElementById("break_duration_mins") as HTMLSelectElement).value),
                            depots                        : Object.values(this.state.depotsByName),
                            ...(tectransit.agency.on_demand_enabled ? {
                                max_order_duration_mins       : Number((document.getElementById(`max_order_duration_mins`) as HTMLInputElement).value),
                                max_lateness_seconds          : Number((document.getElementById("max_lateness_seconds") as HTMLSelectElement).value),
                                order_modal_start_ahead_mins  : (Number((document.getElementById("order_modal_start_ahead_hours") as HTMLSelectElement).value)*60)
                            } : {})
                        })}>Save</button>
                    </div>
                </div>
            );
        });
    }
}
