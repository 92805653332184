import Depot             from './Depot';
import * as LatLngType   from './LatLng';
import * as RouteType    from './Route';

export type NumRange = [number,number];

export interface Bounds {
    northeast                     : LatLngType.LatLng;
    southwest                     : LatLngType.LatLng;
};

export interface BoardingSeconds {
    ambulatory                      : number;
    wheelchair                      : number;
}

export interface Agency {
    _id                             : number;
    name                            : string;
    vehicles_by_driver_id           : Record<number,number>;
    bounds                          : Bounds;
    workminutes                     : NumRange[][];              // One per each weekday (the range is in minutes counted from start of the day)
    holidays                        : Record<string,string>;     // Map from 'yyyymmdd' to the name of the holiday, e.g. '2023/01/16' => 'MLK Day'
    boundaries?                     : LatLngType.LatLng[][];     // an agency can have multiple disjointed areas
    depots                          : Depot[];
    shift_duration_mins             : number;
    break_duration_mins             : number;
    time_zone                       : string;
    startup_ms                      : number;
    current_ms                      : number;
    accommodations                  : string[];
    demographics_profile            : Record<string,string[]>;
    modes_of_transportation         : string[];
    authentication_methods          : string[];
    max_lateness_seconds            : number;                    // If we are late to some point less than this seconds, we allow the route
    gps_accuracy_meters             : number;                    // We compare geo locations upto this accuracy
    dispatcher_advance_seconds      : number;                    // how far in advance to dispatch the pre-scheduled orders (i.e. convert them to "real" orders)
    maneuver_announcement_meters    : number;                    // How many meters ahead to announce a maneuver
    max_routes_to_try               : number;                    // At most how many geo provider to try when scheduling for a new order
    route_prioritization_metrics    : RouteType.Metrics;         // used in selection of the best candidate route out of many, see Server/Bus.ts
    boarding_seconds                : BoardingSeconds;           // How long it takes to board different types of passengers
    phone_number?                   : string;                    // phone number, used in some messaging
    on_demand_enabled               : boolean;
    fixed_route_enabled             : boolean;                   // If true, then the agency supports fixed route
    fixed_route_fares?              : Record<string,number>;     // by passenger category
    external_vehicle_class?         : string;
    max_order_duration_mins?        : number;                    // orders taking longer that this many minutes won't be scheduled
    min_order_duration_mins?        : number;                    // if an order takes less than that, it won't be accepted
    pre_trip_duration_mins?         : number;                    // how many mins is allocated to pre-trip inspections?
    post_trip_duration_mins?        : number;                    // how long post trip inspections takes?
    max_idle_with_passenger_mins?   : number;                    // max time one passenger can wait for another passenger to board.
    max_wait_for_manifest_ms?       : number;                    // max milliseconds to wait for agency manifest
    order_modal_max_advance_mins?   : number;                    // How many minutes ahead the passengers can order
    order_modal_start_ahead_mins?   : number;                    // orders starting fewer than this many mins before ordering won't be allowed
    order_modal_order_types?        : string[];                  // if not null, gives the list of allowed order types in OrderModal. Otherwise API /passenger/order/types gives these types.
    max_regular_hours_day?          : number;                    // For driver payroll reports
    pickup_latlngs_enabled?         : boolean;                   // An easy switch to turn allowed pickup locations on and off.
    dropoff_latlngs_enabled?        : boolean;                   // An easy switch to turn allowed dropoff locations on and off.
    allowed_latlngs?                : LatLngType.NamedLatLng[];  // if not null then gives a list of locatation where users can be picked at or dropped off
    always_show_stop_buttons?       : boolean;                   // if set to `true` then pickup/dropoff/noshow buttons are shown to drivers regardless of how close the drivers are
    next_day_orders_cutoff_hour?    : number;                    // After this hour in agency time zone the next day reservations are disallowed (the day after next OK)
    issue333_paratransit_areas?     : LatLngType.LatLng[][];     // See https://github.com/TecTransit/TecTransit/issues/333
    send_breaks_to_depot?           : boolean;                   // If true, then the driver is sent to the depot for the break. Else breaks happen wherever there is an opportunity
    vms_are_locked?                 : boolean;                   // See https://github.com/TecTransit/TecTransit/issues/361#issuecomment-1458605203
    skip_dvis                       : boolean;                   // If true, then drivers are not required to do daily vehicle inspections
    skip_ptis                       : boolean;                   // If true, then drivers are not required to do post trip inspections
    hidden_dvi_checkboxes?          : string[];                  // List of DVI indexes to hide on pre-trip/post-trip. See https://github.com/TecTransit/TecTransit/issues/401
    strict_fixed_route_matching?    : boolean;                   // If true, then name of FR route assigned to the bus must exactly match the name of FR route the bus is searched for.
    min_reroute_seconds             : number;                    // server will wait this many seconds between vehicle reroutings
    reroute_seconds_backoff         : number;                    // server will increate the number of seconds to wait between vehicle reroutings with this exponent
    passenger_reverse_geocode_api?  : boolean;                   // If true, then the server will expose passenger api for reverse geocoding
    public_reverse_geocode_api?     : boolean;                   // If true, then the server will expose public api for reverse geocoding. Be VERY CAREFUL enabling this
    fr_trip_mins_between_stops?     : number;                    // The max number of minutes between stops to allow "holdover" passengers on subsequent trips
    issue422_public_key?            : string;                    // See https://github.com/TecTransit/TecTransit/issues/422
    simulate_reported_location?     : boolean;                   // if true then then vehicle is always shown to be were it is supposed to be (i.e. demo mode)
    issue426_mode?                  : boolean;                   // See https://github.com/TecTransit/TecTransit/issues/426 
    issue428_s3_bucket_name?        : string;                    // See https://github.com/TecTransit/TecTransit/issues/428
}

export const getDriverIdsByVehicleId = ( agency:Agency, vehicle_id_:number ) : number[] => {
    return Object.entries(agency.vehicles_by_driver_id)
        .filter(([driver_id,vehicle_id])=>(vehicle_id===vehicle_id_))
        .map(([driver_id,vehicle_id])=>Number(driver_id))
        .sort();
}

export const getBoardingSeconds = ( agency:Agency, order:{ seats:number, wheelchairs:number } ) : number => {
    return ((order.seats||0)*agency.boarding_seconds.ambulatory+(order.wheelchairs||0)*agency.boarding_seconds.wheelchair);
}

export default Agency;
