import React              from 'react';
import {
    Route,
    NavLink }             from "react-router-dom";

import * as images              from 'images/';
import Map                      from 'Dispatcher/Map';
import DRoutes                  from 'Dispatcher/Routes';
import Users                    from 'Dispatcher/Users';
import Whiteboard               from 'Dispatcher/components/Whiteboard';
import Manifests                from 'Dispatcher/components/Manifests';
import User                     from 'Dispatcher/components/User';
import StandingOrder            from 'Dispatcher/components/StandingOrder';
import AssignedOrders           from 'Dispatcher/Orders/Assigned';
import LateOrders               from 'Dispatcher/Orders/Late';
import UnassignedOrders         from 'Dispatcher/Orders/Unassigned';
import Profile                  from 'components/Profile';
import Role                     from 'utils/Role';
import tectransit               from 'utils/TecTransit';
import App                      from 'App';

export const Index = ( app:App ) : Role => {
    const roleRoot          = "/Dispatcher";
    const menuLinkClassName = "menu__link";
    const items = [
        <div className="menu__list-title">Dispatcher</div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Map`}>
                <images.DispatcherMap/>
                <span className="menu__name">Map</span>
            </NavLink>
        </div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Routes`}>
                <images.DispatcherRoutes/>
                <span className="menu__name">Routes</span>
            </NavLink>
        </div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Users?inRoles=Driver,Maintenance,Dispatcher,Manager,Observer&title=Staff`}>
                <images.DispatcherStaff/>
                <span className="menu__name">Staff</span>
            </NavLink>
        </div>
    ];
    if( tectransit.agency.fixed_route_enabled ) {
        if( tectransit.agency.issue422_public_key )
            items.push(
                <div className="menu__item">
                    <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Users?inRoles=Passenger&ninRoles=Driver,Maintenance,Dispatcher,Manager,Observer&title=Passengers`}>
                        <images.DispatcherPassengers/>
                        <span className="menu__name">Passengers</span>
                    </NavLink>
                </div>
            );
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/FixedRoutes/Whiteboard`}>
                    <images.DispatcherWhiteboard/>
                    <span className="menu__name">Whiteboard</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/FixedRoutes/Manifest`}>
                    <images.DriverManifest/>
                    <span className="menu__name">Manifests</span>
                </NavLink>
            </div>
        );
    }
    if( tectransit.agency.on_demand_enabled ) {
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/OnDemand/Whiteboard`}>
                    <images.DispatcherWhiteboard/>
                    <span className="menu__name">Whiteboard</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/OnDemand/Manifest`}>
                    <images.DriverManifest/>
                    <span className="menu__name">Manifests</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Users?inRoles=Passenger&ninRoles=Driver,Maintenance,Dispatcher,Manager,Observer&title=Passengers`}>
                    <images.DispatcherPassengers/>
                    <span className="menu__name">Passengers</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <div className={`${menuLinkClassName} menu__subtitle`}>
                    <images.DispatcherOrders/>
                    <span className="menu__name">Orders</span>
                </div>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName} menu__subtitle-item`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Orders/Assigned`}>
                    <span className="menu__name">Assigned</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName} menu__subtitle-item`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Orders/Unassigned`}>
                    <span className="menu__name">Unassigned</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName} menu__subtitle-item`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Orders/Late`}>
                    <span className="menu__name">Late</span>
                </NavLink>
            </div>
        );
    }
    const needProfile = !tectransit.user.roles.some(r=>["Passenger","Driver"].includes(r));
    if( needProfile ) {
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Profile`}>
                    <images.Profile/>
                    <span className="menu__name">Profile</span>
                </NavLink>
            </div>
        );
    }
    const routes = [
        <Route
            key={"dispatcher"}
            path={roleRoot}
            element={<Map/>} />,
        <Route
            key={"dispatcher_map"}
            path={`${roleRoot}/Map`}
            element={<Map/>} />,
        <Route
            key={"dispatcher_routes"}
            path={`${roleRoot}/Routes`}
            element={<DRoutes/>} />,
        <Route
            key={"scheduled_order"}
            path={`${roleRoot}/StandingOrder/:id`}
            element={<StandingOrder/>} />,
        <Route
            key={"dispatcher_user"}
            path={`${roleRoot}/User/:id`}
            element={<User/>} />,
        <Route
            key={"dispatcher_users"}
            path={`${roleRoot}/Users`}
            element={<Users/>} />
    ];
    if( tectransit.agency.fixed_route_enabled ) {
        routes.push(
            <Route
                key={"dispatcher_fixedroutes_whiteboard"}
                path={`${roleRoot}/FixedRoutes/Whiteboard`}
                element={<Whiteboard type="fixedroute"/>} />,
            <Route
                key={"dispatcher_fixedroutes_manifest"}
                path={`${roleRoot}/FixedRoutes/Manifest`}
                element={<Manifests type="fixedroute"/>} />
        );
    }
    if( tectransit.agency.on_demand_enabled ) {
        routes.push(
            <Route
                key={"dispatcher_ondemand_whiteboard"}
                path={`${roleRoot}/OnDemand/Whiteboard`}
                element={<Whiteboard type="ondemand"/>} />,
            <Route
                key={"dispatcher_ondemand_manifest"}
                path={`${roleRoot}/OnDemand/Manifest`}
                element={<Manifests type="ondemand"/>} />,
            <Route
                key={"dispatcher_orders_assigned_all"}
                path={`${roleRoot}/Orders/Assigned`}
                element={<AssignedOrders/>} />,
            <Route
                key={"dispatcher_orders_assigned_late"}
                path={`${roleRoot}/Orders/Late`}
                element={<LateOrders/>} />,
            <Route
                key={"dispatcher_orders_unassigned"}
                path={`${roleRoot}/Orders/Unassigned`}
                element={<UnassignedOrders/>} />
        );
    }
    if( needProfile ) {
        routes.push(
            <Route
                key={"dispatcher_profile"}
                path={`${roleRoot}/Profile`}
                element={<Profile/>} />
        );
    }
    return {
        name  : "Dispatcher",
        root  : roleRoot,
        items,
        routes
    };
}

export default Index;
