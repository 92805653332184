import * as CapacitorCore   from '@capacitor/core';
import {
    NetworkInterface,
    TecTransITPlugin
}                           from './TecTransITPlugin';

const tectransITPlugin = CapacitorCore.registerPlugin<CapacitorCore.Plugin & TecTransITPlugin>(
    'TecTransITPlugin',
    {
        web: () => import('./web').then(m => new m.TecTransITWeb()),
    }
);

export interface OpenTcpPortArgs {
    port        : number;
    eventName   : string;
}
export interface Result<T extends {}> {
    args        : T;
    handle      : CapacitorCore.PluginListenerHandle;
}
export const buildConfig = (
) : Promise<Record<string,any>> => {
    return tectransITPlugin.buildConfig();
}
export const getNetworkInterfaces = (
) : Promise<Record<string,NetworkInterface>> => {
    return tectransITPlugin.getNetworkInterfaces();
}
export const openTcpPort = (
    port        : number,
    onData      : (data: any, sendSocket:((data:any)=>Promise<void>), closeSocket:(()=>Promise<void>)) => void
) : Promise<Result<OpenTcpPortArgs>> => {
    const args = {
        port      : port,
        eventName : `tcp port ${port}`,
    };
    return tectransITPlugin.openTcpPort(args).then(() => {
        const listenerFunc = (event:{
            remoteAddress : string,
            data          : any
        }) : void => {
            return onData(
                event.data,
                (data:any) => {
                    return tectransITPlugin.sendSocket({
                        port,
                        remoteAddress : event.remoteAddress,
                        data
                    });
                },
                () => {
                    return tectransITPlugin.closeSocket({
                        port,
                        remoteAddress : event.remoteAddress
                    });
                }
            );
        }
        return tectransITPlugin.addListener(args.eventName,listenerFunc).then((handle:CapacitorCore.PluginListenerHandle) => {
            return {
                args,
                handle
            };
        });
    });
}
export const closeTcpPort = (openTcpPortResult:Result<OpenTcpPortArgs>) : Promise<void> => {
    return tectransITPlugin.closeTcpPort(openTcpPortResult.args).then(() => {
        return openTcpPortResult.handle.remove();
    });
}