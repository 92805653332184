import React,{ FC }             from 'react';
import DatePicker               from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { Link,useParams }       from "react-router-dom";
import * as UserType            from 'shared/types/User';
import * as VehicleType         from 'shared/types/Vehicle';
import * as OrderType           from 'shared/types/Order';
import * as ManifestType        from 'shared/types/Manifest';
import * as DurationType        from 'shared/types/Duration';
import rehash                   from 'shared/utils/rehash';
import consts                   from 'shared/consts';
import dayjs                    from 'shared/utils/day-timezone';

import * as images              from 'images/';
import * as MenuItem            from 'components/MenuItem';
import * as googleMapsLinks     from 'utils/googleMapsLinks';

import Alert                    from 'utils/Alert';
import tectransit               from 'utils/TecTransit';
import getApiPromise            from 'utils/getApiPromise';
import deletedOrderStyle        from 'utils/deletedOrderStyle';

interface TheState {
    type            : ('ondemand'|'fixedroute');
    alert           : Alert;
    manifest        : ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>;
    vehicle         : VehicleType.Vehicle;
    manifestMoment  : dayjs.Dayjs;
}
interface ScheduledOrderProps {
    ndx             : number;
    order           : OrderType.Order<string>;
}
const ScheduledOrder : FC<ScheduledOrderProps> = props => {
    if( !props.order.user?._id ) {
        return (<div className="manifest-inner">
            <div className="manifest-count">{props.ndx+1}</div>
            <div className="manifest-order-item">
                <span style={deletedOrderStyle} title={props.order._id}>deleted order</span>
            </div>
        </div>);
    }
    return (
        <div className="manifest-inner">
            <div className="manifest-count">{props.ndx+1}</div>
            <div className="manifest-order-item">
                <div className="manifest-item-wrap">
                    <div className="manifest-user">
                            <Link to={`/Driver/Passenger/${props.order.user_id}`}>
                                <img width={50} alt={props.order.user?.name} src={props.order.user?.picture}/>
                                {props.order.user?.name}
                            </Link>
                    </div>
                    <div className="manifest-user-content">
                        <div className="manifest-user-item">
                            <div className="manifest-map-title">
                                <images.Pickup/>
                                Pickup:
                            </div>
                            <div className="manifest-map-info">
                                {googleMapsLinks.getPlace(props.order.pickup.location,props.order.pickup.address,googleMapsLinks.onlyFirstLineAddressFormatter)}
                                {(props.order.type==='pickup_ordered_at') && (<> at <b>{dayjs(props.order.ordered_at).tz(tectransit.agency.time_zone).format("HH:mm")}</b></>)}
                            </div>
                        </div>
                        <div className="manifest-user-item">
                            <div className="manifest-map-title">
                                <images.Dropoff/>
                                Dropoff:
                            </div>
                            <div className="manifest-map-info">
                                {googleMapsLinks.getPlace(props.order.dropoff.location,props.order.dropoff.address,googleMapsLinks.onlyFirstLineAddressFormatter)}
                                {(props.order.type==='dropoff_ordered_at') && (<>  at <b>{dayjs(props.order.ordered_at).tz(tectransit.agency.time_zone).format("HH:mm")}</b></>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface RouteStepProps {
    ndx         : number;
    step        : ManifestType.Step.Hydrated<OrderType.Order<string>>;
    nextStep    : (ManifestType.Step.Hydrated<OrderType.Order<string>>|undefined);
}
const RouteStep : FC<RouteStepProps> = props => {
    const step = props.step;
    const getOrderSpan = ( so:OrderType.Order<string>, ndx:number ) => {
        const contents = !so.user?._id ? <span style={deletedOrderStyle} title={so._id}>deleted order</span> : (<Link to={`/Dispatcher/Passenger/${so?.user_id}`}>
            {so?.user?.name}
        </Link>);
        return (<span key={so._id}>
            {(ndx===0)?(' of '):(', ')}
            { contents }
        </span>);
    }
    const [
        stepName,
        stepLatLng,
        stepAddress
    ] = (step.name==='fixedroute') ? [
        step.frTrip!.name,
        step.frTrip!.stops[0],
        step.frTrip!.stops[0].name
    ] : [
        step.name,
        step.latlng,
        step.address
    ];
    return (
        <div className="manifest-inner">
            <div className="manifest-count">{props.ndx+1}</div>
            <div className="manifest-inwrap">
                {step.latlng && (
                    <div className="manifest-user-item">
                        <div className="manifest-map-title">Location:</div>
                        <div className="manifest-map-info">{googleMapsLinks.getPlace(stepLatLng,stepAddress,googleMapsLinks.onlyFirstLineAddressFormatter)}</div>
                    </div>
                )}
                <div className="manifest-inwrap">
                    <div className="manifest-user-item">
                        <div className="manifest-map-title">{stepName}{step.etc?` (${step.etc})`:''}</div>
                        <div className="manifest-map-info">
                            at <b>{dayjs(step.seconds!*1000).tz(tectransit.agency.time_zone).format("HH:mm")}</b> for {DurationType.secondsToHMS(step.duration!)}
                            {((step.pickups?.length||0)>0) && (<div>
                                <strong>pickup</strong>
                                {step.pickups?.map(getOrderSpan)}
                            </div>)}
                            {((step.dropoffs?.length||0)>0) && (<div>
                                <strong>dropoff</strong>
                                {step.dropoffs?.map(getOrderSpan)}
                            </div>)}
                            {((step.seconds!+step.duration!)<(props.nextStep?.seconds||0)) && (
                            <>
                                &nbsp;and {DurationType.secondsToHMS(props.nextStep!.seconds!-step.seconds!-step.duration!,60)} wait
                            </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface ManifestProps {
    state    : TheState;
}
const Manifest : FC<ManifestProps> = props => {
    const depot     = tectransit.agency.depots.find(d=>(d.name===props.state.vehicle.depot_name));
    const problems  = ManifestType.VehicleManifest.getProblems(props.state.manifest);
    if( (props.state.manifest.steps||[]).length<1 )
        problems.push(`got empty route`);
    return (
        <div>
            <div className="manifest-alert">
                At <span>{dayjs(props.state.manifest.requested_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</span> dispatcher assigned you the following route
                for the day of&nbsp;<strong>{dayjs(props.state.manifest.dayStartAt).tz(tectransit.agency.time_zone).format("MM/DD/YYYY")}</strong>.
                { props.state.manifest.committed_at ? (<div>
                    {(props.state.manifest.committed_driver_id===tectransit.user._id) ? ' You ' : ` Driver #{state.manifest.committed_driver_id} `}
                    have committed to it at <span>{dayjs(props.state.manifest.committed_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</span>
                    from IP address <span>{props.state.manifest.committed_ip}</span>.
                </div>) : undefined }
            </div>
            <div className="section-card top-1">
                <div className="manifest-item">
                    <div className="manifest-title">Depot:</div>
                    <div className="manifest-text">{depot ? googleMapsLinks.getPlace(depot,depot.name) : 'n/a'}</div>
                </div>
                <div className="manifest-item">
                    <div className="manifest-title">Vehicle Fleet #:</div>
                    <div className="manifest-text">{props.state.manifest.vehicle_id}</div>
                </div>
                {(props.state.type==='ondemand') && (
                    <div className="section-card__header">
                        <div className="section-card__title"><br/>Orders</div>
                    </div>
                )}
                <div className="manifest-orders top-1">
                    {Object
                        .values(ManifestType.Step.getOrdersById(props.state.manifest.steps))
                        .map((order,ndx)=>(<ScheduledOrder key={ndx} ndx={ndx} order={order}/>))}
                </div>
                { (problems.length>0) ? (
                    <div className="text-center"><br/><b>
                        {problems.join(',')}</b>
                    </div>
                ) : (
                  <div>
                      <div className="section-card__header">
                          <div className="section-card__title">Route</div>
                      </div>
                      <div className="manifest-orders top-1">
                              {props.state.manifest.steps.map((step,ndx,steps)=>(<RouteStep key={ndx} ndx={ndx} step={step} nextStep={steps[ndx+1]}/>))}
                              <div className="manifest-total">
                                  <div className="manifest-total-title">Total Distance</div>
                                  <div className="manifest-total-text">{Math.round(props.state.manifest.distance/consts.meters_in_mile*100)/100} miles</div>
                              </div>
                              {
                                  Object.entries(props.state.manifest.steps.reduce((acc,step) => {
                                      acc[step.name] = (acc[step.name]||0)+step.duration!;
                                      return acc;
                                  },{} as Record<string,number>)).map( ([name,seconds],sndx) => (
                                      <div key={sndx} className="manifest-total">
                                          <div className="manifest-total-title">{name}</div>
                                          <div className="manifest-total-text">{DurationType.secondsToHMS(seconds)}</div>
                                      </div>
                                  ))
                              }
                      </div>
                  </div>
                ) }
            </div>
        </div>
    );
};

interface Props {
    type : ('fixedroute'|'ondemand');
}

export default function ManifestMenuItem( props:Props ) {

    const params                 = useParams() as { yyyymmdd?:string };

    const [manifest,setManifest]                    = React.useState<ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>> & {err?:string}|undefined>(undefined);
    const [vehicle,setVehicle]                      = React.useState<VehicleType.Vehicle & {err?:string}|undefined>(undefined);

    const [manifestMoment,setManifestMoment] = React.useState<dayjs.Dayjs>(
        dayjs.tz(/^\d{4}-\d{2}-\d{2}$/.test(params.yyyymmdd||'')?params.yyyymmdd:Date.now(),tectransit.agency.time_zone).startOf('day')
    );
    const setDehydratedManifest = (dehydratedVm:ManifestType.VehicleManifest.Dehydrated<string>) => {
        if( dehydratedVm.err )
            throw Error(dehydratedVm.err);
        const usersById = rehash(dehydratedVm.usersById,user=>UserType.hydrate(user));
        return setManifest(ManifestType.VehicleManifest.hydrate(
            dehydratedVm,
            rehash(dehydratedVm.ordersById,order=>OrderType.hydrate(order,usersById)),
            dehydratedVm.fixedRouteStopsById||{}
        ));
    };
    const state = {
        type    : props.type,
        alert   : new Alert(),
        manifest,
        vehicle,
        manifestMoment
    } as TheState;

    const manifestYYYYMMDD = manifestMoment.format('YYYY-MM-DD');
    React.useEffect(() => {
        Promise.all([
            getApiPromise<ManifestType.VehicleManifest.Dehydrated<string>>(`/api/driver/${props.type}/manifest`,'GET',undefined,{date:manifestYYYYMMDD})
                .then(setDehydratedManifest)
                .catch( err => {
                    state.alert.set(`Cannot load manifest (${err.message})`);
                }),
            getApiPromise<VehicleType.Vehicle>('/api/driver/vehicle')
                .then(setVehicle)
                .catch( err => {
                    state.alert.set(`Cannot load vehicle (${err.message})`);
                })
        ]);
    },[manifestYYYYMMDD,props.type]);

    const onConfirmReceiptOfManifest = ( e:any ) => {
        e.preventDefault();
        e.stopPropagation();
        getApiPromise<ManifestType.VehicleManifest.Dehydrated<string>>(`/api/driver/${props.type}/manifest`,'POST',ManifestType.VehicleManifest.dehydrate(state.manifest))
            .then(setDehydratedManifest)
            .catch( err => {
                state.alert.set(`Cannot save manifest (${err.message})`);
            });
    }
    const getDatePicker = () => {
        return (<DatePicker
            selected={manifestMoment.toDate()}
            onChange={(date:Date) => {
                setManifestMoment(dayjs(date).tz(tectransit.agency.time_zone).startOf('day'));
            }}
        />);
    }
    return MenuItem.withMenuItem("Route Manifest", alert_ => {
        state.alert = alert_;
        if( !manifest )
            return (<div style={{textAlign:'center'}}>Loading manifest...</div>);
        if( manifest.err ) {
            state.alert.set(`Cannot load manifest (${manifest.err})`);
            return;
        }
        if( !vehicle )
            return (<div style={{textAlign:'center'}}>Loading vehicle...</div>);
        if( vehicle.err ) {
            state.alert.set(`Cannot load vehicle (${vehicle.err})`);
            return;
        }
        if( manifest.vehicle_id<0 ) {
            return (
                <table style={{margin:'auto'}}>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                Dispatcher has not yet assigned you a route for the day of <strong>{dayjs(manifest.dayStartAt).tz(tectransit.agency.time_zone).format("MM/DD/YYYY")}</strong>.
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                See driver manifests at other days:
                            </td>
                            <td valign="top">
                                {getDatePicker()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
        return (<div className="wrapper">
            <Manifest state={state}/>
            <table style={{margin:'auto'}}>
                <tbody>
                    {(state.manifestMoment>=dayjs.tz(Date.now(),tectransit.agency.time_zone).startOf('day')) && (
                        <tr>
                            <td colSpan={2}>
                                <button className="btn-theme" onClick={e=>onConfirmReceiptOfManifest(e)}>I confirm the receipt of these instructions and will follow them</button>
                                <br/>&nbsp;
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td valign="top">
                            See driver manifests at other days:
                        </td>
                        <td valign="top">
                            {getDatePicker()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);
    });

}
