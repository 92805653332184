import React                from 'react';
import { 
    Link,
    NavigateFunction
}                           from 'react-router-dom';

import * as UserType        from 'shared/types/User';
import * as VehicleType     from 'shared/types/Vehicle';
import * as DVIRecordType   from 'shared/types/DVIRecord';
import dayjs                from 'shared/utils/day-timezone';

import InfiniteTable        from 'components/InfiniteTable';

import tectransit           from 'utils/TecTransit';
import dviDefectCheckboxes  from 'utils/dviDefectCheckboxes';

export interface DVIType extends DVIRecordType.DVIRecord {
    drivers     : UserType.User[];
    selected?   : boolean;
}
export interface Props {
    vehicle     : VehicleType.Vehicle;
    dvis        : DVIType[];
    navigate    : NavigateFunction;
    selectable? : boolean;
}
export class DVISTable extends InfiniteTable {

    // @ts-expect-error
    public  props       : Props;
    private dviDefects  = Object.assign({},...dviDefectCheckboxes);

    fetchRows( orderby:string, pageSize:number, skip:number ) {
        return Promise.resolve(this.props.dvis);
    }
    renderThead() {
        return (
            <tr>
                <th onClick={()=>this.sort('ms')}>{this.getSortingIcon('ms')}DVI Date</th>
                <th onClick={()=>this.sort('driver_id')}>{this.getSortingIcon('driver_id')}Driver</th>
                <th onClick={()=>this.sort('form.remarks')}>{this.getSortingIcon('form.remarks')}Remarks</th>
                <th onClick={()=>this.sort('form.odometer')}>{this.getSortingIcon('form.odometer')}Odometer</th>
                {tectransit.agency.issue428_s3_bucket_name && (<th>Photos</th>)}
                <th>Defects</th>
                <th>{this.props.selectable?'✔':''}</th>
            </tr>
        );
    }
    renderRow( dvi:(DVIType & { drivers:UserType.User[] }) ) {
        const driver = (dvi.drivers||[])[0];
        const driver_name = driver ? [driver.given_name,driver.family_name].filter(n=>!!n).join(" ") : dvi.driver_id;
        const backgroundColor = (this.props.selectable?dvi.selected:dvi.repair_id) ? 'lightyellow' : 'white'; 
        return (
            <tr key={`${dvi.driver_id}@${dvi.vehicle_id}@${dvi.ms}`} style={{backgroundColor}}>
                <td>{dayjs(dvi.ms).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</td>
                <td>{driver_name}</td>
                <td>{dvi.form.remarks}</td>
                <td>{dvi.form.odometer}</td>
                {tectransit.agency.issue428_s3_bucket_name && (<td>
                    {((dvi.form.photoKeys||[]) as string[]).map((key,ndx)=>{
                        return (<div key={ndx}>
                            <Link 
                                to   ={`/Maintenance/Photo`}
                                state={{
                                    vehicle : this.props.vehicle,
                                    dvi     : dvi,
                                    key     : key
                                }}
                            >
                                Photo #{ndx+1}
                            </Link>
                        </div>);
                    })}
                </td>)}
                <td>{Object.keys(dvi.form).filter(k=>((k in this.dviDefects) && dvi.form[k])).map(k=>this.dviDefects[k]).join(', ')}</td>
                {this.props.selectable ? (<td>
                    <input 
                        type="checkbox" 
                        name="selected" 
                        value={dvi._id} 
                        defaultChecked={dvi.selected} 
                        onClick={() => {
                            dvi.selected = !dvi.selected;
                            this.setState({
                                rows : this.state.rows
                            });
                        }}
                    />
                </td>) : (<td>
                    <button 
                        className="btn-theme btn-small"
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.navigate(`/Maintenance/${dvi.repair_id?'Repairs':'NewRepair'}`,{
                                state : {
                                    vehicle : this.props.vehicle,
                                    dvi     : dvi
                                }
                            });
                        }}
                    >
                        {dvi.repair_id ? `View Repair` : `Create Repair`}
                    </button>
                </td>)}
            </tr>
        );
    }
    renderTfoot( colSpan:number ) {
        if( this.state.hasMore )
            return null;
        return (
            <tr key="-2">
                <td colSpan={colSpan} className="text-center"><strong>Total DVI Records for Vehicle #{this.props.vehicle._id}</strong>: {(this.state.rows||[]).length}</td>
            </tr>
        );
    }    
}

export default DVISTable;