import LatLng               from './LatLng';
import Route                from './Route';
import * as OrderType       from './Order';
import * as FixedRouteType  from './FixedRoute';

export type Type = 'unknown' | 'Bus' | 'FixedRoute';

export interface TripStats {
    revenue_meters           : number;
    non_revenue_meters       : number;
}

export interface TripStatsRecord extends TripStats {
    // TripStats record in `trip_stats` collection
    start                    : Date;
    at                       : Date;
    location                 : LatLng;
    vehicle_id               : number;
    driver_id                : number;
}

export interface ReportedLocation extends LatLng {
    // These come from https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates
    altitude                 : (number|null);
    accuracy                 : number;
    heading                  : (number|null);
    speed                    : (number|null);
    seconds                  : number;
}

export interface Vehicle<T extends Route<OrderType.OrderTypes|OrderType.IdTypes>=Route<OrderType.Order>> {
    type                     : Type;
    _id                      : number;
    agency_id                : number;
    route                    : T;
    odometer_meters          : number;
    reported_location?       : ReportedLocation & { seconds:number };
    idling_since_ms?         : number;
    moving_since_ms?         : number;
    route_set_seconds?       : number;
    reported_driverid?       : number;                  // per driver's login
    driverid?                : number;                  // per configuration or simulated for demos
    depot_name?              : string;
    seats?                   : number;                  // number of seats
    wheelchairs?             : number;                  // number of wheelchair positions
    license_plate?           : string;
    license_state?           : string;
    make_model_year?         : string;
    vin_number?              : string;
    unavailability_reason?   : string;
    day_trip_stats?          : TripStats;
    archived_at?             : number;                  // in milliseconds
    on_demand_enabled?       : boolean;                 // can this vehicle be used for on-demand orders
    is_online?               : boolean;
    is_online_changed_at?    : number;
    // Fixed Route additions
    fixed_route_name?        : string;                                          // a fixed route name (usually named by color, eg. reg, green, blue etc)
    fixed_route_trip?        : FixedRouteType.Trip;                             // information about service, trip etc
    fixed_route_trip_counts? : FixedRouteType.TripCounts & { seconds:number };  // last trip counts as reported by the vehicle
}

export const getTripStats = ( revenue_meters:number, non_revenue_meters:number ) : TripStats => {
    return {
        revenue_meters,
        non_revenue_meters
    };
};

export const converters = {
    odometer_meters   : ( v:string ) => {
        if( !(/^\d+/).test(v) )
            throw Error(`odometer meters are invalid`);
        return Number(v);
    },
    seats             : ( v:string ) => {
        if( !(/^\d{1,2}$/).test(v) )
            throw Error(`seats are invalid`);
        return Number(v);
    },
    wheelchairs       : ( v:string ) => {
        if( !(/^\d{1,2}$/).test(v) )
            throw Error(`wheelchairs are invalid`);
        return Number(v);
    },
    archived_at       : ( v:string ) => {
        if( v==='' )
            return undefined;
        if( !(/^\d+$/).test(v) )
            throw Error(`Archived should be a positive number`);
        return Number(v);
    },
    license_plate     : ( v:string ) => {
        if( !(/^[a-z0-9]{5,12}$/i).test(v) )
            throw Error(`License plate is invalid`);
        return v;
    },
    license_state     : ( v:string) => {
        if( !/^[A-Z]{2}$/.test(v) )
            throw Error(`License state is invalid`);
        return v;
    },
    on_demand_enabled : ( v:string ) => {
        if( !["true","false"].includes(v) )
            throw Error(`On demand option should be a boolean`);
        return v==="true";
    },
    fixed_route_name  : ( v:string ) => {
        return v; // anything goes
    },
    make_model_year   : ( v:string ) => {
        if( !(/^.+([12]\d{3})$/i).test(v) )
            throw Error(`Make, Model, Year is invalid`);
        return v;
    },
    vin_number        : ( v:string ) => {
        // See https://prnt.sc/26ivbwy
        if( !/^[A-Z0-9]{17}$/.test(v) )
            throw Error(`VIN number is invalid`);
        return v;
    },
    depot_name        : ( v:string ) => {
        if( !(/^[A-Z 0-9,]+$/i).test(v) )
            throw Error(`Depot name is invalid`);
        return v;
    },
    unavailability_reason : ( v:string ) => {
        return String(v).trim(); // anything goes
    }
} as Record<keyof Vehicle,(v:string)=>any>;

export const isValid = <T extends Route<OrderType.OrderTypes|OrderType.IdTypes>>( vehicle:Partial<Vehicle<T>> ) : string => {
    vehicle = vehicle || {};
    return Object.entries(converters).reduce((errors,[key,converter]) => {
        try {
            converter((key in vehicle)?String(vehicle[key as (keyof Vehicle)]).trim():'');
        }
        catch( err ) {
            errors.push((err as Error).message);
        }
        return errors;
    },[] as string[]).join(',');
}

export const wheelchairFareNames = {
    'disabled'      : true,
    'wheelchair'    : true,
    'wheelchairs'   : true
}

export const getSeatTypeByFareName = ( fareName:string ) : ('wheelchairs'|'seats')=> {
    return (fareName.toLowerCase() in wheelchairFareNames) ? 'wheelchairs' : 'seats'
}

export const getSeatTypeCounts = ( passengerCounts:Record<string,number> ) => {
    // The incoming `passengerCounts` is count by category of passengers types (adult,
    // senior, etc). We need to convert these categories into counts by seat type (seats
    // wheelchairs).
    return Object.entries(passengerCounts).reduce( (acc,[key,value]) => {
        acc[getSeatTypeByFareName(key)] += value;
        return acc;
    },{
        seats       : 0,
        wheelchairs : 0
    } as Record<('seats'|'wheelchairs'),number>);
}

export default Vehicle;
